import OutdoorMatchedSearchtermLabel from 'views/AssetListPage/components/header/AssetSearch/OutdoorMatchedSearchtermLabel';
import React from 'react';
import { components } from 'react-select';
type menuOptionsType = { value: string; label: string };

const OptionMenu: React.FC = (props: any) => {
  const {
    searchTerm,
    menuOptions,
    handleClickOptionSearch,
    optionText = 'for',
    title = 'DO YOU WANT TO SEARCH IN:',
  } = props;

  return (
    <div className="asyncSearch__menu-option-container">
      <div className="dropdownMessage">{title}</div>
      {menuOptions.map((option: menuOptionsType) => {
        return (
          <div
            role="button"
            tabIndex={0}
            key={option.value}
            onKeyDown={() => handleClickOptionSearch(option)}
            onClick={() => handleClickOptionSearch(option)}
          >
            <components.Option {...props}>
              {option.value === 'is_outdoors' ? (
                <>
                  <b>{option.label}</b> is:{' '}
                  <OutdoorMatchedSearchtermLabel searchTerm={searchTerm} />
                </>
              ) : (
                <>
                  <b>{option.label}</b> {optionText}: {searchTerm}
                </>
              )}
            </components.Option>
          </div>
        );
      })}
    </div>
  );
};

export default OptionMenu;
