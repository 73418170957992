import { keys } from "lodash";

export type User = {
  name: string;
  username: string;
  email: string;
  enabled: boolean;
  federated: boolean;
  isResendWelcomeEmailAvailable: boolean;
  groups: string[];
  roles: string[];
}

export enum UserGroup {
  "IOTA" = "iota",
  "ANALYTICS" = "analytics",
}

export class UserDto {
  [key: string]: any;

  user_id: string = "";
  name: string = '';
  username: string = '';
  email: string = '';
  enabled: boolean = true;
  federated: boolean = false;
  is_resend_welcome_email_available: boolean = false;
  groups: string[] = [];
  roles: string[] = [];

  constructor(user?: UserDto) {
    if (user) {
      keys(user).forEach(key => this[key] = user[key]);
    }
  }
}

export class UsersListDto {

  users: UserDto[] = [];
  is_import_in_progress: boolean = false;
  is_failed_import: boolean = false;
  max_import_users_count: number = 0;
}