import { TABLETDEVICEWIDTH } from 'config/constants';
import styled from 'styled-components';

export const HeaderStyled = styled.header`
  display: flex;
  width: 100%;
  align-items: left;
  margin: 10px 0 30px 0;

  @media (max-width: 1230px) {
    flex-direction: column;
    align-items: flex-start;
    .header-item {
      margin-bottom: 10px;
      width: 100%;
    }
  }
  .header-item {
    display: flex;
    align-items: left;

    &.search {
      max-width: 780px;
      .input-container {
        margin-right: 55px;
      }
      .asyncSearch {
        min-width: 375px;
      }
    }

    &.title {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  @media (max-width: ${TABLETDEVICEWIDTH}px) {
    margin: 20px 0 0 0;
  }
`;

export const StyledSubHeading = styled.h5`
  margin: 5px 0 0;
`;

export const SearchStyleDiv = styled.div`
  margin-bottom: 10px;

  .search-container {
    background-color: white;
    padding: 10px 15px;
    margin: 10px -15px;
    position: relative;

    .input-container {
      max-width: 100%;
    }
    .asyncSearch {
      max-width: 100%;
    }

    .close-search {
      position: absolute;
      padding: 0;
      right: 26px;
      top: 22px;
    }
  }
`;
