import { useDataTableStore, useUserManagementStore } from "contexts/RootStore.context";
import { useState } from "react";
import { alertErrorMessage, alertSuccessMessage } from "utils/alerts";
import { UserDto } from "../UserDto";
import { exportUsers } from "api/config/users";
import { ErrorResponseDto } from "api/config/ErrorResponseDto";

export function useUser() {

  const {
    CreateUser,
    EditUser,
    LoadUsers,
    setUsersLoading,
    ResetUserPassword,
    setShowChangesModal,
    filters,
  } = useUserManagementStore();

  const {
    stopEditingRow,
    isNewRow,
    editingRowValues,
    isEditingRowChanged,
    startEditRow,
    AddEmptyRow,
    setScrollingButtonShow,
  } = useDataTableStore();

  const [showExport, setShowExport] = useState(false);

  const AddRowHandler = (index: number) => {
    setScrollingButtonShow(false);
    if (isEditingRowChanged) {
      setShowChangesModal(true)
    }
    else {
      AddEmptyRow(new UserDto(), index);
    }
  }

  const EditRowHandler = (index: number) => {
    setScrollingButtonShow(false);
    if (isEditingRowChanged) {
      setShowChangesModal(true)
    }
    else {
      startEditRow(index);
    }
  }

  const saveHandler = () => {
    if (isNewRow) {
      AddUserHandler(editingRowValues);
    }
    else {
      EditUserHandler(editingRowValues)
    }
    setShowChangesModal(false);
    stopEditingRow();
  }

  const AddUserHandler = (values: any) => {
    setUsersLoading(true)
    CreateUser(values)
      .then(() => {
        alertSuccessMessage(`${values.name || values.username} user has been added`)
        LoadUsers().then(() => {
          setUsersLoading(false);
        });
      })
      .catch((error: ErrorResponseDto) => {
        setUsersLoading(false)
        alertErrorMessage(error.errorMsg)
      })
  }

  const EditUserHandler = (values: any) => {
    setUsersLoading(true)
    EditUser(values)
      .then(() => {
        alertSuccessMessage(`Changes saved to the user`)
        LoadUsers().then(() => {
          setUsersLoading(false)
        });
      })
      .catch((error: ErrorResponseDto) => {
        setUsersLoading(false)
        alertErrorMessage(error.errorMsg)
      })
  }

  const ResetUserPasswordHandler = (values: UserDto) => {
    setUsersLoading(true);
    ResetUserPassword(values)
      .then(() => {
        alertSuccessMessage(`Welcome email sent successfully`)
        LoadUsers().then(() => {
          setUsersLoading(false)
        });
      })
      .catch((error: ErrorResponseDto) => {
        alertErrorMessage(error.errorMsg)
      })
      .finally(() => setUsersLoading(false))
  }

  const exportHandler = () => {
    setShowExport(true);
    exportUsers(filters)
      .catch(() => {
        alertErrorMessage(`unexpected error occured`)
      })
      .finally(() => {
        setShowExport(false);
      })
  }

  return {
    AddRowHandler,
    EditRowHandler,
    saveHandler,
    ResetUserPasswordHandler,
    showExport,
    exportHandler,
  };
}
