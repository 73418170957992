import React, { useEffect } from 'react';
import AssetTableContainer from './components/AssetTableContainer';
import HeaderAssetSearch from 'views/AssetListPage/components/header/HeaderAssetSearch';
import AssetListPageProvider from 'contexts/assetListPage.context';
import { ScrolledMain } from 'components/ScrolledMain';
import HeaderDefault from 'components/Header/HeaderDefault';
import usePageInfo from 'hooks/pageInfo.hook';
import useDevice from 'hooks/useDevice';

const AssetListPage: React.FC = () => {
  const { headerAssetCount } = usePageInfo();
  const { isMobileDevice } = useDevice();
  useEffect(() => {
    // remove asset details localstorage key on page load to prevent contamination of asset details
    localStorage.removeItem('assetDetails');
  }, []);

  return (
    <AssetListPageProvider>
      {!isMobileDevice &&
        <HeaderDefault itemCount={headerAssetCount} CountTitle='Total Asset' />
      }
      <HeaderAssetSearch />
      <ScrolledMain>
        <div className="container">
          <AssetTableContainer />
        </div>
      </ScrolledMain>
    </AssetListPageProvider>
  );
};
export default AssetListPage;
