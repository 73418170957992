import { icons } from "assets/icons";
import { Label } from "components/Label";
import { ChangeMap, ComponentSchema, PropsSchema } from "components/Renderer/Renderer";
import { Content, Horizontal, Vertical, VerticalSpacer } from "gls";
import React, { useCallback, useEffect, useState } from "react";
import { arrayMove, SortEndHandler } from "react-sortable-hoc";
import shortid from "shortid";
import { style } from "typestyle";
import { schemaHelper, SchemaProvider } from "./helpers/schema";
import OrderableList from "./OrderableList";
import { useDeepCompareMemo } from "use-deep-compare";
import { map } from "../map";
import IconButton from "components/Button/IconButton";
import { GenericModal } from "components/Modals/GenericModal";
import { RoundedIcon } from "components/RoundedIcon";
import { colours } from "styling/colours";
import { alertSuccessMessage } from "utils/alerts";

type DeleteModalProps = {
  title: string;
  subtitle: string;
  itemName?: string;
};

export type ComplexListProps = {
  value: Record<string, unknown>[];
  errors?: Record<string, string[]>;
  onChangeMap?: Record<string, ChangeMap>;
  label?: string;
  addLabel?: string;
  disableAddButton?: boolean;
  onChange: (
    value: Record<string, unknown>[],
    errors?: Record<string, string[]>,
    onChangeMap?: Record<string, ChangeMap>,
    index?: number
  ) => void;
  onBlur?: (index: number) => void;
  itemPropsSchema: PropsSchema[];
  // If the index of the element has a key in itemPropsSchemaOverride then this schema will be used
  // instead of the itemPropsSchema
  itemPropsSchemaOverride?: Record<number, PropsSchema[]>;
  editable?: boolean;
  className?: string;
  ariaLabel?: string;
  minItems?: number;
  maxItems?: number;
  deleteModalProps?: DeleteModalProps;
  canRearrange?: boolean;
  setDeleteItemName?: (index: number) => string;
};

export const ComplexListOptions: ComponentSchema = {
  label: "ComplexList",
  type: "ComplexList",
  icon: "ListIcon",
  propsSchema: [
    {
      id: "label",
      type: "Input",
      props: {
        label: "Label",
      },
    },
    {
      id: "editable",
      type: "Toggle",
      props: {
        label: "Editable",
      },
    },
    {
      id: "itemPropsSchema",
      type: "PropsSchemaBuilder",
      props: {
        label: "Label",
      },
    },
    {
      id: "ariaLabel",
      type: "Input",
      props: {
        label: "Aria Label",
      },
    },
  ],
};

const inputId = shortid.generate();
const inputNumberId = shortid.generate();

export const ComplexListDefaultProps = {
  label: "Complex List",
  itemPropsSchema: [
    {
      id: inputId,
      type: "Input",
      props: {
        label: "Item",
        placeholder: "Item name",
        type: "text",
      },
    },
    {
      id: inputNumberId,
      type: "InputNumber",
      props: {
        label: "Quantity",
        min: 0,
        max: 10,
      },
    },
  ],
  defaultValue: [
    {
      [inputId]: "Bed",
      [inputNumberId]: 1,
    },
  ],
};

let deleteIndex = -1;

export const ComplexList = (props: ComplexListProps) => {
  const modalSubtitlePrefix = props.deleteModalProps?.subtitle;
  const {
    value,
    errors,
    onChangeMap,
    itemPropsSchema,
    itemPropsSchemaOverride = {},
    onChange,
    onBlur,
    editable,
    disableAddButton = false,
    ariaLabel = "Complex List",
    maxItems = Number.MAX_VALUE,
    minItems = 1,
    setDeleteItemName,
  } = props;

  const schemaProvider = useDeepCompareMemo(() => {
    return new SchemaProvider(itemPropsSchema, itemPropsSchemaOverride)
  }, [itemPropsSchema, itemPropsSchemaOverride]);

  const [isOpen, setOpen] = useState(false);
  const [modalSubtitle, setModalSubtitle] = useState(modalSubtitlePrefix || "");
  const [itemName, setItemName] = useState(
    props.deleteModalProps?.itemName || "Item"
  );
  const [schedules, setSchedules] = useState(0)
  const { deleteItem, reArrangeItems } = schemaHelper(schemaProvider);

  const createNewItem = useCallback((index: number) => {
    return schemaProvider.get(index)?.reduce((itemAcc, propsSchema) => {
      const componentOptions = map.get(propsSchema.type);
      const defaultValue = componentOptions?.defaultProps?.defaultValue;
      const hasDefaultValue = defaultValue !== undefined;

      if (!hasDefaultValue) return itemAcc;

      return {
        ...itemAcc,
        [propsSchema.id]: defaultValue,
      };
    }, {});
  }, [schemaProvider]);

  useEffect(() => {
    if (value.length < minItems) {
      const newItems = new Array(minItems - value.length).fill(null).map((_, i) => createNewItem(i));

      onChange([...value, ...newItems]);
    }
  }, [createNewItem, minItems, onChange, value]);

  const createNewOnChangeMap = (index: number) => {
    const newOnChangeMap: Record<string, ChangeMap> = {};
    schemaProvider.get(index).forEach((prop) => {
      newOnChangeMap[prop.id + index] = {
        required: !!prop.props?.required,
        changed: false,
      };
    });

    return newOnChangeMap;
  };

  const handleChange = (
    newValue: Record<string, unknown>,
    i: number,
    newErrors?: Record<string, string[]>,
    newOnChangeMap?: Record<string, ChangeMap>
  ) => {
    if (value.length > schedules) {
      setSchedules(value.length)
    }
    value[i] = newValue;

    onChange([...value], newErrors, newOnChangeMap, i);
  };

  const handleBlur = (
    index: number,
  ) => {
    if (onBlur) {
      onBlur(index);
    }
  }

  const handleAdd = () => {
    const index = value.length;
    const newItem = createNewItem(index);
    const newOnChangeMap = createNewOnChangeMap(index);
    onChange([...value, newItem], errors, {
      ...(onChangeMap || {}),
      ...newOnChangeMap,
    });
  };

  const handleDeleteItem = (index: number) => {
    if (setDeleteItemName) {
      const itemName = setDeleteItemName(index);
      setModalSubtitle(`${modalSubtitlePrefix} ${itemName}`);
      setItemName(itemName);
    }

    if (props.deleteModalProps) {
      deleteIndex = index;
      setOpen(true);
    } else {
      handleDelete(index);
    }
  };

  const handleDelete = (index: number) => {
    if (value.length > 1) {
      setSchedules(schedules - 1)
      const newValue = value.slice();
      newValue.splice(index, 1);
      const newErrors = deleteItem(errors, index);
      const newOnChangeMap = deleteItem(onChangeMap, index);

      onChange(newValue, newErrors, newOnChangeMap);
    }
  };

  const handleConfirmDelete = () => {
    handleDelete(deleteIndex);
    setOpen(false);
    alertSuccessMessage(<span>{itemName} has been deleted</span>)
  };

  const handleSort: SortEndHandler = ({ oldIndex, newIndex }) => {
    document.body.style.cursor = "default";
    props.onChange(
      arrayMove(props.value, oldIndex, newIndex),
      reArrangeItems(errors, oldIndex, newIndex),
      reArrangeItems(onChangeMap, oldIndex, newIndex)
    );
  };

  const handleSorting = () => {
    document.body.style.cursor = "grabbing";
  };

  if (!schemaProvider.isValid()) {
    return (
      <span aria-label={ariaLabel} id={ariaLabel}>
        Configure PropsSchema!
      </span>
    );
  }

  return (
    <>
      <Vertical
        sizing="stretch"
        spacing={0}
        className={props.className}
        aria-label={ariaLabel}
        id={ariaLabel}
      >
        {props.label && <Label value={props.label} />}
        {value && value.length ? (
          <OrderableList
            {...props}
            onSortEnd={handleSort}
            onSortStart={handleSorting}
            pressDelay={300}
            shouldCancelStart={() => !props.canRearrange}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleDeleteItem={handleDeleteItem}
            isOpen={isOpen}
          />
        ) : (
          <Content
            horizontalAlign="center"
            className={emptyStyle}
            aria-label={`${ariaLabel} No Items`}
            id={`${ariaLabel} No Items`}
          >
            No items on the list.
          </Content>
        )}
        <VerticalSpacer space={12} />
        {editable && value.length < maxItems && (
          <Content horizontalAlign="right">
            <IconButton
              className={addButtonStyle}
              onClick={handleAdd}
              disabled={disableAddButton}
              aria-label={`${ariaLabel} Edittable Button`}
              id="complex-list-add-button"
              icon={
                <Horizontal spacing={6}>
                  <icons.AddTaskIcon
                    width={18}
                    height={18}
                    fill={colours.secondary}
                  />
                  <span>{props.addLabel || "Add Item"}</span>
                </Horizontal>
              }
            />
          </Content>
        )}
      </Vertical>

      {props.deleteModalProps && (
        <GenericModal
          title={props.deleteModalProps.title}
          subtitle={modalSubtitle}
          actionName="Delete"
          actionStyle="cancel"
          icon={
            <RoundedIcon>
              <icons.OutlineDeleteIcon
                height={30}
                width={30}
                fill={colours.error}
              />
            </RoundedIcon>
          }
          isOpen={isOpen}
          onClose={() => setOpen(false)}
          onAction={handleConfirmDelete}
        />
      )}
    </>
  );
};

const emptyStyle = style({
  position: "relative",
  border: "1px dashed #dadada",
  padding: "27px",
  $nest: {
    "&:hover": {
      background: "#f5f6fa",
    },
  },
});

const addButtonStyle = style({
  cursor: "pointer",
  background: "none",
  border: "none",
  fontSize: "16px",
  display: "flex",
  justifyContent: "flex-end",
  color: colours.secondary,
  $nest: {
    "&:focus": {
      outline: "none",
    },
  },
});
