import { Vertical } from "gls/lib";
import { observer } from "mobx-react";
import { useRef } from "react";
import { ScrollButton } from "./ScrollButton";
import useDevice from "hooks/useDevice";
import { usePageScroll } from "./usePageScroll";

export type ScrollingContainerProps = {
  children: React.ReactNode;
  topHeight?: number;
  topButtonButtomPosition?: number;
};

const ScrollingContainer = observer(
  (props: ScrollingContainerProps) => {
    const { dimension } = useDevice();
    const boundingElementRef = useRef<HTMLDivElement>(null);
    const elementRef = useRef<HTMLDivElement>(null);
    const { handleScroll, showOnScroll } = usePageScroll(
      boundingElementRef,
      elementRef
    );

    return (
      <Vertical
        spacing={0}
        style={{
          maxHeight: dimension.height - (props.topHeight ?? 90),
          minHeight: dimension.height - (props.topHeight ?? 90),
          overflowY: "auto",
          overflowX: "hidden",
          paddingRight: 4,
        }}
        ref={boundingElementRef}
        scroll="vertical"
      >
        <Vertical ref={elementRef} sizing="stretch" spacing={0}>
          {props.children}
        </Vertical>
        {showOnScroll && <ScrollButton onClick={handleScroll} bottom={`${props.topButtonButtomPosition ?? 10}px`} />}
      </Vertical>
    );
  }
);

export default ScrollingContainer;
