import { style } from "typestyle"

type LoadMaskProps = {
  isShow: boolean
}
export const LoadMask = ({ isShow }: LoadMaskProps) => {

  if (!isShow) {
    return (<></>)
  }
  return (
    <div className={loadingStyle}>
      <div className={loadingIconStyle}></div>
    </div>
  )
}

const loadingStyle = style({
  position: 'absolute',
  top: 0,
  left: 0,
  marginLeft: "0px !important",
  width: '100%',
  height: '100%',
  zIndex: 10000,
  backgroundColor: '#eee9',
  backgroundClip: "content-box",
  color: 'white',
  textAlign: 'center',
});

const loadingIconStyle = style({
  width: '48px',
  height: '48px',
  border: '5px solid #91DBF6',
  borderBottomColor: 'transparent',
  position: "absolute",
  top: "40%",
  left: "40%",
  borderRadius: '50%',
  display: 'inline-block',
  boxSizing: 'border-box',
  animation: 'rotation 1s linear infinite',
  verticalAlign: 'middle',
})