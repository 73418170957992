import { observer } from "mobx-react";
import { useEffect } from "react";
import UsersDataTable from "./components/UsersDataTable";
import HeaderDefault from "components/Header/HeaderDefault";
import ScrollingContainer from "components/ScrollContainer/ScrollingContainer";
import LoadingScreen from "components/Loading/LoadingScreen";
import useDevice from "hooks/useDevice";
import { useUserManagementStore } from "contexts/RootStore.context";
import WidthNotSupportedPage from "views/WidthNotSupportedPage/WidthNotSupportedPage";
import { ErrorBanner } from "components/Header/components/ErrorBanner";

const UsersManagement = observer(() => {

  const { isMobileDevice } = useDevice();
  const {
    dataLoading,
    dataLoadingError,
    setUsersLoading,
    LoadData,
    LoadUsers,
    reset,
    gridData,
  } = useUserManagementStore();

  useEffect(() => {

    reset();
    const abortController = new AbortController();

    if (!isMobileDevice) {
      LoadData().then(() => {
        LoadUsers().then(() => {
          setUsersLoading(false)
        }).finally(() => {
          if (abortController.signal.aborted) {
            reset();
          }
        });
      });
    }
    return () => {
      abortController.abort();
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isMobileDevice) {
    return <WidthNotSupportedPage />;
  }

  return (
    <>
      {dataLoading && <LoadingScreen />}
      {!dataLoading && (
        <>
          <HeaderDefault
            CountTitle="Total Users"
            itemCount={gridData.length}
          />
          {dataLoadingError && <ErrorBanner errorMsg="Error loading data" />}
          <ScrollingContainer>
            <UsersDataTable />
          </ScrollingContainer>
        </>
      )}
    </>
  );
})

export default UsersManagement;