import ExportAssetsApi from 'api/assets/export.api';
import Button from 'components/Button/Button';
import { AuthenticationContext } from 'contexts/authentication.context';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { alertErrorMessage, alertSuccessMessage } from 'utils/alerts';
import { getIdToken, programaticallyDownloadFileFromHref } from 'utils/utils';
import DownloadAssetsModal from './DownloadAssetsModal';
import { AssetListPageContext } from 'contexts/assetListPage.context';

const StyledButtonContainer = styled.div`
  margin-right: 10px;
`;
export default function DownloadAssetsButton() {
  const [modalOpen, setModalOpen] = useState(false);

  const { authState } = useContext(AuthenticationContext);
  const { downloadAssetsButtonDisabled } = useContext(AssetListPageContext);

  const idToken = getIdToken(authState);
  const exportAssetsApi = new ExportAssetsApi(idToken);

  function handleFileStatus(res: any, interval: any) {
    // processing (also has progress value 0 -1 ) - the job is in progress.
    // export_succeeded (terminal) - the job succeded.
    // failed (terminal) - the job failed.
    const progressData = res.data;

    switch (progressData.status) {
      case 'processing':
        break;

      case 'export_succeeded':
        // note we close modal in useEffect after 3 seconds.
        clearInterval(interval);
        programaticallyDownloadFileFromHref(res.data.url);
        setModalOpen(false);
        alertSuccessMessage('File has been downloaded successfully');
        break;

      case 'failed':
        handleError(res)
        break;
    }
  }

  function handleError(err: any) {
    console.log(err);
    setModalOpen(false);
    alertErrorMessage('Error downloading file');
  }

  function handleExport() {
    setModalOpen(true);
    // get job ID
    exportAssetsApi.createNewJob()
      .then((res) => {
        return res.data.job_id;
      })
      // pass JobID to export endpoint
      .then((job_id) => {
        const interval = setInterval(() => {
          exportAssetsApi.getJobStatus(job_id)
            .then((res) => {
              handleFileStatus(res, interval);
            })
            .catch((e: any) => {
              clearInterval(interval);
              handleError(e);
            });
        }, 1000);
      })
      .catch((err) => {
        handleError(err);
      });
  }

  return (
    <StyledButtonContainer>
      <Button
        style={{ width: '170px' }}
        outline
        onClick={handleExport}
        disabled={downloadAssetsButtonDisabled}
      >
        Download Assets
      </Button>
      {modalOpen && <DownloadAssetsModal />}
    </StyledButtonContainer>
  );
}
