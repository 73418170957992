import { icons } from "assets/icons";
import { Portal } from "components/Portal";
import { Content, Horizontal } from "gls/lib";
import { useOutsideAlerter } from "hooks/useOutsideAlerter";
import React, { useEffect, useRef, useState } from "react";
import { colours } from "styling/colours";
import { style } from "typestyle";

type Position = {
  top: number;
  left: number;
};

export enum VerticalPos {
  Top,
  Bottom,
}

export enum HorizontalPos {
  Left,
  Right,
}

export enum ActionType {
  click,
  hover,
}

type TooltipProps = {
  horizontalPos?: HorizontalPos;
  verticalPos?: VerticalPos;
  element: JSX.Element;
  topOffset?: string;
  actionType?: ActionType;
  errorMessage?: string[];
};

export const ErrorTooltip = (props: TooltipProps) => {
  const { horizontalPos, verticalPos, errorMessage } = props;
  const ref = useRef(null);
  const [showing, setShowing] = useState(false);
  const getRef = React.useCallback(() => ref, [ref]);

  return (
    <>
      <span
        ref={ref}
        onMouseEnter={() => {
          if (errorMessage) {
            setShowing(true);
          }
        }}
        onMouseLeave={() => {
          setShowing(false);
        }}
      >
        {props.element}
      </span>
      {showing && (
        <TooltipContent
          getParentRef={getRef}
          verticalPos={verticalPos}
          horizontalPos={horizontalPos}
          onClose={() => setShowing(false)}
        >
          <Horizontal verticalAlign="top" spacing={6} className={errorStyle}>
            <Content>
              <icons.ErrorBangIcon className={errorIconStyle} />
            </Content>
            <span key={`datagrid-err`}>{errorMessage ? errorMessage[0] : ""}</span>
          </Horizontal>
        </TooltipContent>
      )}
    </>
  );
};

type TooltipContentProps = {
  children: React.ReactNode;
  horizontalPos?: HorizontalPos;
  verticalPos?: VerticalPos;
  onClose: () => void;
  getParentRef: () => React.RefObject<HTMLDivElement>;
};

export const TooltipContent = (props: TooltipContentProps) => {
  const ref = React.useRef<HTMLDivElement>(null);
  useOutsideAlerter(ref, props.onClose);
  const parentRef = props.getParentRef();
  const parentRect = parentRef.current?.getBoundingClientRect();
  const [position, setPosition] = useState<Position>({
    top: -9999, // out of bounds for first render
    left: -9999, // out of bounds for first render
  });
  const [size, setSize] = useState({
    width: "auto",
    height: "auto"
  });

  useEffect(() => {
    if (!parentRect || !ref || !ref.current) {
      return;
    }
    setPosition({
      top: parentRect.y + 49,
      left: parentRect.x,
    });
    setSize({ ...size, width: `${parentRect.width}px` })

  }, [ref]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Portal>
      <div
        ref={ref}
        className={tooltipContentStyle(position.top, position.left, size.width, size.height)}
      >
        {props.children}
      </div>
    </Portal>
  );
};

const tooltipContentStyle = (top: number, left: number, width: string, height: string) => {
  return style({
    top,
    left,
    width,
    height,
    position: "absolute",
    padding: "10px",
    background: colours.error,
    fontSize: "15px",
    color: "white",
    zIndex: 3,
  });
};

const errorStyle = style({
  fontWeight: "normal",
  fontSize: "12px",
  maxWidth: "100%",
});

const errorIconStyle = style({
  $nest: {
    path: {
      fill: "white",
    },
  }
})