import { HeaderGroup } from "react-table"
import { DataTableStyle } from "../style"
import { icons } from "assets/icons";
import { classes, style } from "typestyle";
import { Horizontal } from "gls/lib";

type DataGridHeaderProps = {
  headerGroups: HeaderGroup<{}>[];
}
export const DataGridHeader = ({ headerGroups }: DataGridHeaderProps) => {
  return (
    <>
      {headerGroups.map((headerGroup: any, index: number) => (
        <div className="header" key={`react-table-group-header-column-${index}`}>
          <div {...headerGroup.getHeaderGroupProps()} className={classes(DataTableStyle.headertr, headerRowStyle(index))}>
            {headerGroup.headers.map((column: any, colIndex: number) => (
              <Horizontal spacing={10} {...column.getHeaderProps(column.isSortable ? column.getSortByToggleProps() : "")}
                className={classes(DataTableStyle.th, headerBlockStyle(column))}
                key={`react-table-header-column-${colIndex}`}>
                <span>{column.render('Header')}</span>
                <span className={style({ verticalAlign: "top" })}>&nbsp;
                  {column.isSortable &&
                    <icons.SortIcon fill="#0055B8" />
                  }
                </span>
                {!column.disableResizing &&
                  <div
                    {...column.getResizerProps()}
                    className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
                  />
                }
              </Horizontal>
            ))}
          </div>
        </div>
      ))}
    </>
  )
}

const headerRowStyle = (index: number) => style({
  borderTop: index > 0 ? "0px !important" : "auto",
})

const headerBlockStyle = (column: any) => style({
  borderBottom: column.columns?.length === 1 ? "0px !important" : "auto"
})