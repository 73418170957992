import { icons } from "assets/icons";
import { RendererComponent } from "components/Renderer/RendererComponent";
import { isString } from "lodash";
import React, { memo, useState } from "react";
import { classes, style } from "typestyle";
import { DataGridColumn } from "../models";
import { DataTableStyle } from "../style";
import { ErrorTooltip } from "./ErrorTooltip";
import { PropsSchema } from "components/Renderer/Renderer";


type DataGridCellProps = {
  cellId: string,
  cellValue: JSX.Element,
  rowValues: string,
  rowIndex: number,
  column: DataGridColumn,
  schema: PropsSchema,
  editingMode: boolean,
  editingRowValues?: Record<string, any>,
  changeRowValues?: ((value: any, columnId: string) => void) | null,
  errors?: Record<string, string[]>,
  isNewRow?: boolean,
}

const DataGridCell = memo(({
  cellId,
  cellValue,
  rowValues: jsonRowValues,
  column,
  rowIndex,
  schema,
  editingMode,
  editingRowValues = {},
  changeRowValues,
  errors = {},
  isNewRow = false,
}: DataGridCellProps) => {

  const [showErrorIcon, setShowErrorIcon] = useState(true);
  const rowValues = JSON.parse(jsonRowValues);

  const handleChange = (id: string, value: unknown, errs: string[] = []) => {
    changeRowValues && changeRowValues(value, cellId);

    if (schema.props && "onChange" in schema.props) {
      schema.props["onChange"](cellId, value, editingRowValues, isNewRow);
    }
  };

  if (!editingMode || !schema) {
    const wrapClassName = column.wrapCelText ? "reat-table-cell-text-wrap" : "reat-table-cell-text";
    if (column.cellRender) {
      const val = column.cellRender(cellValue, cellId, rowValues, rowIndex);
      if (isString(val))
        return (<div className={wrapClassName}>{val}</div>);
      else
        return val;
    }
    return (<div className={wrapClassName}>{cellValue || ''}</div>);
  }

  return (
    <ErrorTooltip
      errorMessage={errors[cellId]}
      element={
        <div className={classes("cell-container", errors[cellId] ? DataTableStyle.cellError : "")}>
          <RendererComponent
            id={`datatable-editor-${rowIndex}-${cellId}`}
            key={`datatable-editor-${rowIndex}-${cellId}`}
            schema={schema}
            value={editingRowValues[cellId]}
            onChange={handleChange}
            onFocus={() => {
              if (!["MultiSelectCheckbox", "SearchableSelect", "MultiSelect", "Select"].includes(schema.type))
                setShowErrorIcon(false)
            }}
            onBlur={() => setShowErrorIcon(true)}
            isDisabled={column.editable ? !column.editable(editingRowValues, cellId, isNewRow) : false}
            error={errors[cellId]}
          />
          {column.required && column.required(editingRowValues, cellId, isNewRow) &&
            (column.editable ? column.editable(editingRowValues, cellId, isNewRow) : true) &&
            < div className={DataTableStyle.required} >*</div>
          }
          {errors[cellId] &&
            <icons.ErrorBangIcon className={classes(DataTableStyle.iconError, showErrorIcon ? "show" : "hide")} />
          }
        </div >
      } />
  )
})

export default DataGridCell;

export const DataTableCellCheckboxStyle = style({
  justifyContent: 'center',
  paddingTop: '15px',
})
