import { icons } from "assets/icons";
import { useSystemAdminPermissionStore, useUserManagementStore } from "contexts/RootStore.context";
import { observer } from "mobx-react";
import { TableInstance } from "react-table";
import { colours } from "styling/colours";
import { classes, style } from "typestyle";
import { useUser } from "../hooks/useUser";
import IconButton from "components/Button/IconButton";


type AddUserProps = {
  table?: TableInstance
}
export const AddUserButton = observer(({ table }: AddUserProps) => {

  const { canManageUsers } = useSystemAdminPermissionStore();
  const { usersLoading, isAnyPendingImportProcess } = useUserManagementStore();
  const { AddRowHandler } = useUser();

  if (!canManageUsers || usersLoading) {
    return (<></>)
  }
  return (
    <div className={addRowStyle} >
      <div className={classes("content", isAnyPendingImportProcess ? "disabled" : "")}>
        <IconButton
          disabled={isAnyPendingImportProcess}
          id='button-add-row'
          icon={
            <icons.AddTaskIcon
              width={18}
              height={18}
              fill={isAnyPendingImportProcess ? colours.disableIcon : colours.secondary}
            />
          }
          onClick={() => { AddRowHandler(table ? table.state.pageIndex * table.state.pageSize : 0) }}
        />
        Add User
      </div>
    </div>
  );
})

const addRowStyle = style({
  padding: '15px 30px 0 20px',
  color: colours.secondary,
  $nest: {
    '& .content': {
      $nest: {
        '& svg': {
          cursor: 'pointer',
          verticalAlign: 'text-top',
          marginRight: '6px',
        }
      }
    },
    '& .disabled': {
      color: colours.tertiaryText,
    }
  }
});