import React, { useContext } from 'react';
import AssetSearchAsyncSelect from 'views/AssetListPage/components/header/AssetSearch/AssetSearchAsyncSelect';
import { RecentUsersContext } from 'contexts/recentUsers.context';

type UsersSearchFieldProps = {
  SearchHanlder: (searchTerm: string, searchIn?: string) => void;
};

const UsersSearchField = ({ SearchHanlder }: UsersSearchFieldProps) => {
  const {
    searchTerm,
    setSearchTerm,
    setSearchCategory,
    setSelectedSearchMenuOptionLabel,
    selectedSearchMenuOptionLabel,
    userSearchOptions,
    isSearching,
  } = useContext(RecentUsersContext);

  return (
    <AssetSearchAsyncSelect
      setSearchCleared={() => SearchHanlder('')}
      searchTerm={searchTerm}
      handleEnterSearchFunc={(searchTerm) => SearchHanlder(searchTerm)}
      handleClickOptionSearchFunc={SearchHanlder}
      setSearchTerm={setSearchTerm}
      isSearching={isSearching}
      menuOptions={userSearchOptions}
      selectedSearchMenuOptionLabel={selectedSearchMenuOptionLabel}
      setSearchCategory={setSearchCategory}
      setSelectedSearchMenuOptionLabel={setSelectedSearchMenuOptionLabel}
      placeholder="Search for users"
      setIsSearching={() => { }}
      width={375}
    />
  );
};

export default UsersSearchField;
