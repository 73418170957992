import React, { useEffect } from 'react';
import useBroadcastChannel from 'hooks/useBroadcastChannel';
import { useLocation, useNavigate } from 'react-router-dom';
import config from 'react-global-configuration';
import { signOut } from 'aws-amplify/auth';

export default function MobileSignoutPage() {

  const { postBroadcastMessage } = useBroadcastChannel();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirectUri = queryParams.get("logout_uri");
  const navigate = useNavigate();

  useEffect(() => {
    postBroadcastMessage('broadcast_signout');

    if (config.get().SHOULD_USE_COGNITO) {
      signOut({
        global: false,
        oauth: {
          redirectUrl: redirectUri ?? undefined
        }
      })
    }
    else {
      navigate("/signout")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="u-flex-center">
      <div className="container"></div>
    </main>
  );
}
