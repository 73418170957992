import { getWindowURL } from './utils';

function getAmplifyConfig(config: any, signoutURL?: string | null): any {
  const CONFIG = config.get();

  let signoutUri = signoutURL ?? `${getWindowURL()}/signout`;

  return {
    Auth: {
      Cognito: {
        userPoolClientId: CONFIG.COGNITO_CLIENT_ID,
        userPoolId: CONFIG.COGNITO_USERPOOL_ID,
        loginWith: {
          // Optional
          oauth: {
            domain: CONFIG.COGNITO_DOMAIN,
            scopes: ['openid', 'profile'],
            redirectSignIn: [`${getWindowURL()}/authentication/callback`],
            // redirectSignOut: signoutURL ? [signoutURL] : [`${getWindowURL()}/signout`],
            redirectSignOut: [signoutUri],
            preferredRedirectUrl: signoutURL,
            responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
          },
        },
      },

      // (optional) - Configuration for cookie storage
      // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
      cookieStorage: {
        // - Cookie domain (only required if cookieStorage is provided)
        domain: `${window.location.hostname}`,
        // (optional) - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
        sameSite: 'none',
        // (optional) - Cookie secure flag
        // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        secure: true,
      },
    },
  };
}
export default getAmplifyConfig;
