import { isArray } from "lodash";
import { useCallback, useEffect } from "react";

export function useOutsideAlerter(refs: any | any[], cb: (e: MouseEvent) => void) {

  const handleClickOutside = useCallback((event: MouseEvent) => {

    if (isArray(refs)) {
      for (let i = 0; i < refs.length; i++) {
        const ref = refs[i];
        if (ref && ref.current && ref.current.contains(event.target)) {
          return;
        }
      }
      cb(event);
    }
    else {
      if (refs && refs.current && !refs.current.contains(event.target)) {
        cb(event);
      }
    }

  }, [cb, refs]);

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      try {
        document.removeEventListener("mousedown", handleClickOutside);
      }
      finally { }
    };
  }, [handleClickOutside]);
}
