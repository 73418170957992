import { icons } from "assets/icons";
import { DataTableCellCheckboxStyle } from "components/DataGrid/components/DataGridCell";
import DataGrid from "components/DataGrid/DataGrid";
import { DataGridColumn } from "components/DataGrid/models";
import { ErrorBanner } from "components/Header/components/ErrorBanner";
import { CheckBox } from "components/inputs/CheckBox/CheckBox";
import MultiSelectCheckbox from "components/inputs/Select/MultiSelectCheckbox";
import { GenericModal } from "components/Modals/GenericModal";
import { RoundedIcon } from "components/RoundedIcon";
import { useDataTableStore, useSystemAdminPermissionStore, useUserManagementStore } from "contexts/RootStore.context";
import { Vertical } from "gls/lib";
import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import { useCallback, useMemo, useState } from "react";
import { TableInstance } from "react-table";
import { UsersStore } from "stores/config/UsersStore";
import { colours } from "styling/colours";
import { style } from "typestyle";
import { EmailValidator } from "utils/formstate";
import { useUser } from "../hooks/useUser";
import FilterUsers from "./FilterUsers";
import OperationsCell from "./OperationsCell";


const UsersDataTable = observer(() => {

  const { canManageUsers } = useSystemAdminPermissionStore();
  const {
    changeRowValues,
    stopEditingRow,
    isValidRow,
    validateCellsValue,
    errors,
  } = useDataTableStore();

  const { saveHandler } = useUser();
  const {
    gridData,
    usersLoading,
    workgroups,
    workgroupToRoles,
    isAnyFailedImportProcess,
    showChangesModal,
    setShowChangesModal,
  } = useUserManagementStore();
  const [table, setTable] = useState<TableInstance>();
  const fullNameCharCount = 50;

  const checkboxCellRender = useCallback((cellValue: any, cellId: string, rowValues: Record<string, any>, rowIndex: number) => {
    return cellValue
      ? <CheckBox disabled
        value=''
        name=''
        containerClassName={DataTableCellCheckboxStyle}
        checked={cellValue}
        onChange={() => { }} />
      : '';
  }, []);

  const rolesEditableHandler = useCallback((rowValues: Record<string, any>, columnId: string, isNewRow: boolean) => {
    return rowValues["enabled"];
  }, []);

  const editableHandler = useCallback((rowValues: Record<string, any>, columnId: string, isNewRow: boolean) => {
    if (rowValues["federated"] && columnId === "name") {
      return false;
    }

    if (isNewRow) {
      return true;
    }
    if (columnId === "federated") {
      return false;
    }
    if (columnId === "username") {
      return false;
    }
    return true;
  }, [])

  const requiredHandler = (rowValues: Record<string, any>, columnId: string, isNewRow: boolean) => {
    if (rowValues["federated"] === true) {
      return false;
    }
    return true;
  }

  const duplicateUsernameValidation = (value: string, columnId: string, rowValues: Record<string, any>, isNewRow: boolean, griddata: Record<string, any>[]) => {
    if (!isNewRow) {
      return "";
    }
    return griddata.filter(row => row.username.toLowerCase() === value.toLowerCase()).length > 0 ? "User with provided user name already exists" : ""
  }

  const fullnameExceedsCharCountLimit = (value: string) => {
    return value.length > fullNameCharCount ? `Full name cannot exceed ${fullNameCharCount} characters.` : ""
  }

  const columns = useMemo(() => {
    let columns: DataGridColumn[] = [{
      accessor: "user_id",
      hidden: true
    }, {
      accessor: "groups",
      hidden: true
    }, {
      accessor: "is_resend_welcome_email_available",
      hidden: true
    }, {
      accessor: "workgroups",
      hidden: true,
      cellRender: () => "",
    }, {
      Header: 'full name',
      accessor: 'name',
      sticky: 'left',
      required: requiredHandler,
      editable: editableHandler,
      validators: [
        (value: string) => {
          const pattern = /^[a-zA-Z/ .]+$/;
          return pattern.test(value) ? "" : "Full Name cannot contain numbers";
        },
        fullnameExceedsCharCountLimit
      ],
      cellRender: (cellValue: any, cellId: string, rowValues: Record<string, any>, rowIndex: number) => {
        if (rowValues && rowValues["federated"]) {
          return rowValues["username"];
        }
        return cellValue;
      },
      editor: {
        id: "name",
        type: "Input",
        props: {
          maxLength: fullNameCharCount + 1,
          showMaxLength: false,
        }
      },
    },
    {
      Header: 'user name',
      accessor: 'username',
      required: () => true,
      editable: editableHandler,
      validators: [
        UsersStore.validateUserName,
        duplicateUsernameValidation
      ],
      editor: {
        id: "username",
        type: "Input",
        props: {
          maxLength: 100,
          showMaxLength: false,
        }
      },
    },
    {
      Header: 'email address',
      accessor: 'email',
      required: requiredHandler,
      editable: editableHandler,
      validators: EmailValidator,
      editor: {
        id: "email",
        type: "Input",
        props: {
          maxLength: 100,
          showMaxLength: false,
        }
      },
    }, {
      Header: 'PHONE number',
      accessor: 'phone_number',
      editable: editableHandler,
      validators: (value) => {
        if (isEmpty(value)) {
          return "";
        }
        const pattern = /^\+[0-9]{12}$/;
        return pattern.test(value) ? "" : "Please enter a valid phone number, with a country code. Eg. +44765...";
      },
      editor: {
        id: "phoneNumber",
        type: "Input"
      },
    }, {
      Header: 'enable user',
      accessor: 'enabled',
      dataAlign: "center",
      colWidth: "110px",
      disableResizing: true,
      cellRender: checkboxCellRender,
      editor: {
        id: 'enabled',
        type: "CheckBox",
        props: {
          onChange: (columnId: string, value: boolean) => {
            if (!value) {
              workgroups.forEach(workgroup => {
                changeRowValues([], workgroup.id)
              });
            }
          }
        },
      },
    }, {
      Header: 'federated user',
      accessor: 'federated',
      dataAlign: "center",
      colWidth: "110px",
      disableResizing: true,
      cellRender: checkboxCellRender,
      editable: editableHandler,
      editor: {
        id: 'federated',
        type: "CheckBox",
        props: {
          onChange: (value: boolean, rowValues: Record<string, any>, isNewRow: boolean) => {
            if (value) {
              changeRowValues((isNewRow ? "N/A " : rowValues["username"]), "name");
            }
          }
        },
      },
    }];

    for (const workgroup of workgroups) {
      columns.push({
        Header: workgroup.name,
        accessor: workgroup.id,
        style: {
          minWidth: "180px",
        },
        editable: rolesEditableHandler,
        editor: {
          id: workgroup.id,
          type: "MultiSelectCheckbox",
          props: {
            options: workgroupToRoles[workgroup.id].map((item) => ({ value: item.id, key: item.name })),
            width: "100%",
            onChange: (columnId: string, value: any) => {
              if (isEmpty(errors[columnId]))
                validateCellsValue()
            }
          },
        },
        cellRender: (cellValue: any, cellId: string, rowValues: Record<string, any>, rowIndex: number) => {
          const values = cellValue;
          if (!values || values.length === 0 || !workgroupToRoles[workgroup.id]) {
            return '';
          }
          if (values.length === 1) {
            const list = workgroupToRoles[workgroup.id].filter(item => item.id === values[0]);
            return list.length > 0 ? list[0].name : '';
          }
          return (
            <MultiSelectCheckbox
              width="100%"
              options={workgroupToRoles[workgroup.id].map((item) => ({ value: item.id, key: item.name }))}
              readonly={true}
              value={values}
              onChange={() => { }}
              borderless
            />)
        }
      })
    }
    if (canManageUsers) {
      columns.push({
        Header: '',
        sticky: 'right',
        accessor: 'operations',
        colWidth: "100px",
        disableResizing: true,
        cellRender: (cellValue: any, cellId: string, rowValues: Record<string, any>, rowIndex: number) =>
          <OperationsCell rowIndex={rowIndex} rowValues={rowValues} />
      });
    }
    return columns;
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []);

  return (
    <>
      <Vertical spacing={0}
        className={style({ height: 'fit-content', marginBottom: "10px" })}>

        <FilterUsers table={table} />

        {canManageUsers && isAnyFailedImportProcess && (
          <ErrorBanner errorMsg="Note: There are some failures on previous import, please check the import page for details." />
        )}

        <div className={style({ marginTop: canManageUsers && isAnyFailedImportProcess ? 0 : 20 })}>
          <DataGrid
            loading={usersLoading}
            ref={el => setTable(el)}
            columns={columns}
            data={gridData}
            enablePagination
            initialState={{ pageSize: 200 }}
          />
        </div>
      </Vertical>

      <GenericModal
        title="Save Changes"
        subtitle="Your unsaved changes will be lost. Do you want to save changes?"
        actionName="Save"
        cancelName="Discard"
        actionStyle="neutral"
        icon={
          <RoundedIcon fillColour={colours.secondaryText}>
            <icons.SaveIcon height={16} width={16} fill={colours.secondaryText} />
          </RoundedIcon>
        }
        isOpen={showChangesModal}
        onClose={() => {
          setShowChangesModal(false)
          stopEditingRow()
        }}
        onAction={() => {
          if (isValidRow) {
            saveHandler();
          }
          else {
            setShowChangesModal(false)
          }
        }}
      />
    </>
  )
})

export default UsersDataTable;
