import { icons } from "assets/icons";
import { Horizontal } from "gls/lib";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { colours } from "styling/colours";
import { style } from "typestyle";
import { WorkGroup } from "../WorkgroupDto";
import IconButton from "components/Button/IconButton";
import { configRoutePath } from "router/routePaths";

export type WorkgroupTableRowProps = {
  workgroup: WorkGroup;
  handleToggleDelete: (open: boolean, workgroup: WorkGroup) => void;
  onEdit: (workgroup: WorkGroup) => void;
  canManage: boolean;
};

export const RowActions = observer(
  ({
    canManage,
    handleToggleDelete,
    onEdit,
    workgroup,
  }: WorkgroupTableRowProps) => {

    const navigate = useNavigate();

    return (
      <Horizontal
        spacing={18}
        verticalAlign="center"
        horizontalAlign="right"
        className={paddingStyle}
      >
        <IconButton
          icon={
            <icons.RolesIcon
              width={18}
              height={18}
              fill={colours.secondary}
            />
          }
          onClick={() => {
            navigate(`${configRoutePath.listRoles}/${workgroup.id}`);
          }}
        />
        {canManage && (
          <>
            <IconButton
              id="edit-workgroup-button"
              icon={
                <icons.EditIconOutlined
                  width={17}
                  height={17}
                  fill={colours.secondary}
                />
              }
              onClick={() => {
                onEdit(workgroup);
              }}
            />
            <IconButton
              id="delete-workgroup-button"
              icon={
                <icons.OutlineDeleteIcon
                  fill={colours.unresponsive}
                  viewBox="7 1 25 25"
                />
              }
              onClick={() => {
                handleToggleDelete(true, workgroup);
              }}
            />
          </>
        )}
      </Horizontal>
    );
  }
);

const paddingStyle = style({
  padding: "20px 0",
});