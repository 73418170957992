import { genericGet, genericPost, genericPut, blobDownload } from "api/config/base";
import { UserDto, UsersListDto } from "views/config/userManagement/UserDto";
import { getConfigApiUrl } from "utils/utils";

export function getUserGroups(): Promise<string[]> {
  return Promise.resolve(["analytics", "iota"])
};

export function getUsers(): Promise<UsersListDto> {
  return genericGet<UsersListDto>(`${getConfigApiUrl()}/v1/users`);
}

export function getLoggedInUser(): Promise<UserDto> {
  return genericGet<UserDto>(`${getConfigApiUrl()}/v1/user`);
}

export function AddUser(user: UserDto): Promise<any> {
  return genericPost<UserDto, any>(`${getConfigApiUrl()}/v1/user`, { ...user })
}

export function UpdateUser(user: UserDto): Promise<any> {
  return genericPut(`${getConfigApiUrl()}/v1/user`, { ...user })
}

export function assignSelfRoles(roles: string[]) {
  return genericPut(`${getConfigApiUrl()}/v1/self/roles`, roles)
}

export function ResetUserPassword(user_id: string) {
  return genericPut(`${getConfigApiUrl()}/v1/user/${user_id}/reset_password`)
}

export function exportUsers(filters: Record<string, string>): Promise<any> {
  return blobDownload(`${getConfigApiUrl()}/v1/users/export`);
}
