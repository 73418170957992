import config from 'react-global-configuration';

class ConfigApi {
  token: string;
  headersConfig: object;

  public constructor(token: string) {
    this.token = token;
    this.headersConfig = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${this.token}`,
      },
    };
  }

  public getAssetSubtypes = async () => {
    const response = await fetch(
      `${config.get().CONFIG_API_URL}/document/entity/asset/fields/subtype`,
      this.headersConfig,
    );
    if (!response.ok) {
      throw new Error('problem fetching data');
    }
    const dataFromServer = await response.json();
    const itemsArray = [];
    const entries = Object.entries(dataFromServer.items);

    for (const [key, value] of entries) {
      itemsArray.push({ value: key, label: value });
    }
    return itemsArray;
  };

  public getAssetOwners = async () => {
    const response = await fetch(
      `${config.get().CONFIG_API_URL}/document/entity/asset/properties/owner`,
      this.headersConfig,
    );
    if (!response.ok) {
      throw new Error('problem fetching data');
    }
    const dataFromServer = await response.json();
    const itemsArray = [];
    const entries = Object.entries(dataFromServer.items);

    for (const [key, value] of entries) {
      itemsArray.push({ value: key, label: value });
    }
    return itemsArray;
  };

  public getSystemServices = async () => {
    const response = await fetch(
      `${config.get().CONFIG_API_URL}/system/services `,
      this.headersConfig,
    );
    if (!response.ok) {
      throw new Error('problem fetching data');
    }
    const data = await response.json();

    return data;
  };

  public getFullWebConfig = async () => {
    const response = await fetch(`${config.get().CONFIG_API_URL}/all/web`, this.headersConfig);
    if (!response.ok) {
      throw new Error('problem fetching data');
    }
    const data = await response.json();

    return data;
  };
}

export default ConfigApi;
