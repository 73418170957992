export enum routePath {

  unauthorised = "/unauthorised",

  assetManagement = '/asset-management',
  beaconManagement = '/beacon-management',
  recentUsers = '/recent-users',
}

export enum configRoutePath {
  systemAdminList = "/config",
  Workgroups = "/config/workgroups",

  userManagement = "/config/user-management/users-list",
  userImport = '/config/user-management/import-users',
  importHistory = '/config/user-management/import-history',
  reImportFailures = '/config/user-management/re-import-failures',

  addRole = "/config/roles/add-role",
  editRole = "/config/roles/edit-role",
  listRoles = "/config/roles/list-roles",

}