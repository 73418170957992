import { Vertical } from "gls"
import { colours } from "styling/colours";
import { style } from "typestyle";

type GroupSectionProps = {
  title?: string;
  children: React.ReactNode;
}
export const GroupSection = (props: GroupSectionProps) => {

  return (
    <Vertical spacing={5}>
      {props.title && (<span>{props.title}</span>)}
      <Vertical spacing={5} className={groupStyle}>
        {props.children}
      </Vertical>
    </Vertical>
  )
}

const groupStyle = style({
  marginTop: "10px !important",
  border: `1px solid ${colours.tertiaryText}`,
  padding: "20px"
});