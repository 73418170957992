import { icons } from "assets/icons";
import { RoundedIcon } from "components/RoundedIcon";
import { Tooltip } from "components/Tooltip";
import { Horizontal } from "gls/lib";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { style } from "typestyle";
import { useUser } from "../hooks/useUser";
import { useDataTableStore, useSystemAdminPermissionStore, useUserManagementStore } from "contexts/RootStore.context";
import IconButton from "components/Button/IconButton";
import { colours } from "styling/colours";
import { GenericModal } from "components/Modals/GenericModal";
import { UserDto } from "../UserDto";

type OperationsCellProps = {
  rowIndex: number,
  rowValues: Record<string, any>,
}

type changePassModal = {
  show: boolean,
  rowValues: Record<string, any> | null,
}

const OperationsCell = observer(({ rowIndex, rowValues }: OperationsCellProps) => {

  const [changePassModal, setChangePassModal] = useState<changePassModal>({
    show: false,
    rowValues: null
  });
  const { isAnyPendingImportProcess } = useUserManagementStore();
  const { canManageUsers } = useSystemAdminPermissionStore();
  const { editingRowIndex,
    stopEditingRow,
    isValidRow,
    isEditingRowChanged,
    setScrollingButtonShow,
  } = useDataTableStore();

  const {
    EditRowHandler,
    saveHandler,
    ResetUserPasswordHandler,
  } = useUser();

  return (
    <>
      <Tooltip
        contentClassName={style({ height: "100%" })}
        content={
          <Horizontal className={style({ height: "100%" })} spacing={0} padding={8}>
            {editingRowIndex !== rowIndex &&
              <>
                {canManageUsers &&
                  <IconButton
                    id='button-edit'
                    disabled={isAnyPendingImportProcess}
                    icon={
                      <icons.EditIconOutlined
                        width={18}
                        height={18}
                        fill={isAnyPendingImportProcess ? colours.disableIcon : colours.secondary}
                      />
                    }
                    onClick={() => EditRowHandler(rowIndex)}
                  />
                }
                {canManageUsers && !rowValues.federated && rowValues.is_resend_welcome_email_available &&
                  <IconButton
                    disabled={isAnyPendingImportProcess}
                    id='button-reset-password'
                    icon={
                      <icons.PasswordResetIcon
                        width={18}
                        height={18}
                        fill={isAnyPendingImportProcess ? colours.disableIcon : colours.secondary}
                      />
                    }
                    onClick={() => {
                      setChangePassModal({
                        show: true,
                        rowValues: rowValues
                      })
                    }}
                  />
                }
              </>
            }
            {editingRowIndex === rowIndex &&
              <>
                <IconButton
                  id='button-edit'
                  icon={
                    <icons.SuccessIcon
                      width={18}
                      height={18}
                      className={editBtnStyle(isValidRow && isEditingRowChanged)}
                    />
                  }
                  disabled={!(isValidRow && isEditingRowChanged)}
                  onClick={() => {
                    saveHandler();
                    setScrollingButtonShow(true);
                  }}
                />
                <IconButton
                  id='button-cancel'
                  icon={
                    <icons.CrossIcon
                      width={18}
                      height={18}
                      fill={colours.error}
                    />
                  }
                  onClick={() => {
                    stopEditingRow();
                    setScrollingButtonShow(true);
                  }}
                />
              </>
            }
          </Horizontal>
        } >
        {isAnyPendingImportProcess ? "Import in progress. Please try again later." : ""}
      </Tooltip>

      <GenericModal
        title="Resend Welcome Email"
        subtitle={<>Are you sure you want to resend welcome email to <b>{changePassModal.rowValues?.name}</b>?</>}
        actionName="Resend"
        cancelName="Cancel"
        icon={
          <RoundedIcon fillColour={colours.secondaryText}>
            <icons.PasswordResetIcon height={18} width={18} fill={colours.secondaryText} />
          </RoundedIcon>
        }
        isOpen={changePassModal.show}
        onClose={() => {
          setChangePassModal({
            show: false,
            rowValues: null
          });
        }}
        onAction={() => {
          if (changePassModal.rowValues) {
            ResetUserPasswordHandler(changePassModal.rowValues as UserDto);
            setChangePassModal({
              show: false,
              rowValues: null
            });
          }
        }}
      />
    </>
  )
});

export default OperationsCell;

const editBtnStyle = (vaild: boolean) => style({
  $nest: {
    path: {
      fill: vaild ? colours.success : colours.tertiaryText,
    },
  }
});