/*
RoleListStore class handles all states, API calls,
  and logic of listing role records.
*/
import { DeleteRole, getRoles, UsersAffectedByRole } from "api/config/roles";
import { getWorkgroups } from "api/config/workgroup";
import { isEmpty } from "lodash";
import { action, makeObservable, observable } from "mobx";
import { RootStore } from "stores/RootStore";
import { KeyValuePair } from "utils/common";
import { RoleListItem } from "views/config/roles/RoleConfigDto";
import { WorkGroup } from "views/config/workgroups/WorkgroupDto";

export class RolesListStore {
  root: RootStore;
  version: string = "";
  listLoading = true;
  actionLoading = false;
  roles: Record<string, RoleListItem[]> = {};
  workgroups: WorkGroup[] = [];
  accessedWorkgroups: KeyValuePair[] = [];
  dataLoadingError: boolean = false;
  selectedWorkgroup: string = "";
  associatedUsersLoading: boolean = false;
  associatedUsersNo: number = 0;
  deleteModalOpen = false;
  roleToDelete: RoleListItem | null = null;

  constructor(root: RootStore) {
    this.root = root;

    makeObservable(this, {
      listLoading: observable,
      workgroups: observable,
      accessedWorkgroups: observable,
      actionLoading: observable,
      dataLoadingError: observable,
      roles: observable,
      selectedWorkgroup: observable,
      associatedUsersNo: observable,
      deleteModalOpen: observable,
      roleToDelete: observable,

      changeWorkgroup: action.bound,
      setListLoading: action.bound,
      LoadData: action.bound,
      deleteRole: action.bound,
      loadAssociatedUsers: action.bound,
      setDeleteModalOpen: action.bound,
      setRoleToDelete: action.bound,
      setActionLoading: action.bound,
      reset: action.bound,
    });
  }

  setDeleteModalOpen(value: boolean) { this.deleteModalOpen = value }
  setRoleToDelete(value: RoleListItem | null) { this.roleToDelete = value }
  setActionLoading(value: boolean) { this.actionLoading = value }

  reset() {
    this.version = "";
    this.listLoading = true;
    this.actionLoading = false;
    this.roles = {};
    this.workgroups = [];
    this.accessedWorkgroups = [];
    this.dataLoadingError = false;
    this.associatedUsersLoading = false;
    this.associatedUsersNo = 0;
    this.deleteModalOpen = false;
    this.roleToDelete = null;
  }

  async loadAssociatedUsers(role: RoleListItem) {
    try {
      const res = await UsersAffectedByRole(role.id);
      this.associatedUsersNo = res;
      return true;
    }
    catch (error) {
      return false;
    }
  }

  async deleteRole(role: RoleListItem) {
    this.actionLoading = true;
    return DeleteRole(role.id)
      .then(() => this.LoadData())
      .finally(() => this.actionLoading = false)
  }

  setListLoading(isLoading: boolean) {
    this.listLoading = isLoading;
  }

  changeWorkgroup(workgroupId: string) {
    this.selectedWorkgroup = workgroupId;
  }

  private fillRoles(data: RoleListItem[]) {
    for (const workgroup of this.workgroups) {
      this.roles[workgroup.id] = data
        .filter(item => item.workgroup.id === workgroup.id)
        .sort((x, y) => x.name.toLowerCase() > y.name.toLowerCase() ? 1 : -1);
    }
  }

  LoadData(selectedWorkgroup?: string) {
    this.setListLoading(true);
    return Promise.all([
      getWorkgroups(),
      getRoles(),
    ])
      .then(res => {
        this.workgroups = res[0];
        this.fillRoles(res[1]);
        if(selectedWorkgroup !== undefined){
          this.selectedWorkgroup = selectedWorkgroup;
        }
        if (isEmpty(this.selectedWorkgroup)) {
          this.selectedWorkgroup = this.workgroups[0].id;
        }
      })
      .catch(() => {
        this.dataLoadingError = true;
      })
      .finally(() => {
        this.setListLoading(false);
      })
  }

}
