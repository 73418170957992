import LoadingScreen from 'components/Loading/LoadingScreen';
import { useSystemAdminPermissionStore } from 'contexts/RootStore.context';
import { observer } from 'mobx-react';
import React from 'react';
import { useLocation } from 'react-router-dom';

type RenderRouteComponentProps = {
  component: React.FC;
  isConfig?: boolean;
}
const RenderRouteComponent = observer(({ component, isConfig }: RenderRouteComponentProps) => {
  // a simple component that passes a unique location key as a prop to the route, ensuring route is re-rendered on every route update,
  // this allows user to click same route and get a component reload.
  const location = useLocation();
  const { loading } = useSystemAdminPermissionStore();

  if (isConfig && loading) {
    return <LoadingScreen />
  }

  return React.createElement(component, { key: location.key });
});
export default RenderRouteComponent;
