import { useContext, useEffect } from 'react';
import { clearWindowURLParams, getIdToken, setWindowURLParams } from 'utils/utils';
import { AuthenticationContext } from 'contexts/authentication.context';
import { alertErrorMessage } from 'utils/alerts';
import { RecentUsersContext } from 'contexts/recentUsers.context';
import UserApi from 'api/user/user.api';
import { outdoorLocationString } from 'views/AssetListPage/components/header/AssetSearch/AssetSearchAsyncSelect';

export default function useRecentUsers() {
  const authContext = useContext(AuthenticationContext).authState;
  const token = getIdToken(authContext);

  const {
    searchTerm,
    searchCategory,
    setSearchCategory,
    sortColumnField,
    sortDirection,
    setSortColumnField,
    setSortDirection,
    setTotalCount,
    setUsersList,
    setIsSorting,
    setSearchTerm,
    setIsLoading,
    setIsSearching,
    setSelectedSearchMenuOptionLabel,
    userSearchOptions,
    setNetworkError,
    isSearching,
    isLoading,
    limit,
  } = useContext(RecentUsersContext);

  const loadData = async (
    sortColumnField = '',
    sortDirection = '',
    searchTerm = '',
    searchCategory = '',
  ) => {
    setIsLoading(true);
    const userApi = new UserApi(token);
    return userApi
      .getUsersMostRecentUpdateFirst(
        limit,
        sortColumnField,
        sortDirection,
        searchTerm,
        searchCategory,
      )
      .then((res) => {
        const { items, total } = res.data;
        setTotalCount(total);
        setUsersList(items);
      })
      .catch((err) => {
        alertErrorMessage('Could not fetch Users');
        console.error(err);
        setNetworkError(err);
      })
      .finally(() => {
        setIsLoading(false);
        setIsSorting(false);
      });
  };

  const SortHandler = async (sortColumn: string, sortDirection: string) => {
    setSortColumnField(sortColumn);
    setSortDirection(sortDirection);
    setIsSorting(true);
    loadData(sortColumn, sortDirection, searchTerm, searchCategory);
  };

  function SearchHandler(searchTerm: string, searchCategory: string = '') {
    clearWindowURLParams();
    const termTrimmed =
      searchCategory === 'is_outdoors' ? outdoorLocationString : searchTerm.trim();
    setSearchTerm(termTrimmed);
    setSearchCategory(searchCategory);

    if (termTrimmed !== '') {
      setWindowURLParams(['term', termTrimmed]);
    }
    if (searchCategory !== '') {
      setWindowURLParams(['category', searchCategory]);
    }
    setIsSearching(searchTerm !== '');
    loadData(sortColumnField, sortDirection, termTrimmed, searchCategory);
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const searchTerm = params.get('term') || '';
    const searchCategory = params.get('category') || '';
    setSearchTerm(searchTerm);
    setSearchCategory(searchCategory);

    if (searchTerm !== '') {
      setIsSearching(true);
    }
    if (searchCategory !== '') {
      setSelectedSearchMenuOptionLabel(
        userSearchOptions.filter((entry) => entry.value === searchCategory)[0].label,
      );
    }

    loadData(sortColumnField, sortDirection, searchTerm, searchCategory);

    return () => {
      setIsSearching(false);
      setIsLoading(true);
      setSortColumnField('-last_update');
      setSortDirection('asc');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoading && isSearching && searchTerm !== '') {
      SearchHandler('');
      setIsSearching(false);
      setSearchTerm(searchTerm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  return {
    SortHandler,
    SearchHandler,
  };
}
