import { isString } from "lodash";
import { useCallback } from "react";
import { Row } from "react-table";
import { classes } from "typestyle";
import { DataTableStyle } from "../style";
import DataGridCell from "./DataGridCell";

type DataGridRowProps = {
    row: Row,
    hideCellValuesTooltip?: boolean,
    editingMode: boolean,
    editingRowValues?: Record<string, any>,
    changeRowValues?: ((value: any, columnId: string) => void),
    errors?: Record<string, string[]>,
    isNewRow?: boolean,
}
export const DataGridRow =  ({
    row,
    editingMode,
    hideCellValuesTooltip,
    editingRowValues,
    changeRowValues,
    errors,
    isNewRow,
}: DataGridRowProps) => {

    const tooltipRender = useCallback((cell: any) => {
        if (hideCellValuesTooltip) {
            return "";
        }
        let tooltip = cell.value;
        if (cell.column.tooltipRender) {
            tooltip = cell.column.tooltipRender(cell);
        }
        return isString(tooltip) ? tooltip : "";
    }, [hideCellValuesTooltip]);

    return (
        <div {...row.getRowProps()} key={`DataTable-row-${row.index}`}
            className={DataTableStyle.bodytr}>
            {row.cells.map((cell: any, cellIndex: number) => {
                return (
                    <div
                        {...cell.getCellProps()}
                        key={`DataTable-cell-${row.index}-${cellIndex}`}
                        title={tooltipRender(cell)}
                        className={classes(
                            DataTableStyle.td,
                            editingMode ? "editing" : "",
                            cell.column.cellStyle ? cell.column.cellStyle(cell) : "",
                        )}>
                        <DataGridCell
                            key={`datatable-cell-${row.index}-${cell.column.id}`}
                            cellId={cell.column.id}
                            cellValue={cell.value}
                            rowValues={JSON.stringify(row.values)}
                            rowIndex={row.index}
                            column={cell.column}
                            schema={cell.column.editor}
                            editingMode={editingMode}
                            editingRowValues={editingRowValues}
                            changeRowValues={changeRowValues}
                            errors={errors}
                            isNewRow={isNewRow}
                        />
                    </div>
                )
            })
            }
        </div>
    );
};