import { AssetManagementContext } from 'contexts/assetManagement.context';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'antd';
import {
  Indicator,
  StyledNavLinkItem,
  StyledNavMenuButton,
  StyledNavLinkWithSubItem,
} from './styled';
import rightArrowIcon from './icons/rightArrow.svg';
import ComponentUserPermissionRenderWrapper from 'wrappers/ComponentConditionWrappers/ComponentUserPermissionRenderWrapper';
import ComponentFeaturePermissionRenderWrapper from 'wrappers/ComponentConditionWrappers/ComponentFeaturePermissionRenderWrapper';
import { navItemsType } from './Navigation';

function checkLinkIsSelected(link: navItemsType, activeHref: string): boolean | undefined {
  // function to check if link has matchingpaths, and if they match current href.
  // otherwise check if the links path matches current href.
  const linkHasMatchingPaths = link.matchingPaths;
  return linkHasMatchingPaths ? link.matchingPaths?.includes(activeHref) : link.href === activeHref;
}
function checkSubmenuContainsActiveHref(submenu: any[], activeHref: string) {
  // check if submenu contains current active href
  const submenuHrefsArray = submenu.map((item) => item.href);
  return submenuHrefsArray.includes(activeHref);
}

export default function NavLinkItem({
  link,
  navIsCollapsed,
  activeHref,
  setActiveHref,
  setNavIsCollapsed,
  setUserHasCollapsed,
  closeMobileMenu,
}: {
  link: navItemsType;
  navIsCollapsed: boolean;
  activeHref: string;
  setActiveHref: (arg: string) => void;
  setNavIsCollapsed: (arg: boolean) => void;
  setUserHasCollapsed: (arg: boolean) => void;
  closeMobileMenu?: () => void;
}) {
  const [submenuParentClickTimestamp, setsubmenuParentClickTimestamp] = useState<number | null>(
    null,
  ); // used to trigger useEffect when parent clicked
  const [subMenuOpen, setSubMenuOpen] = useState(() => {
    if (link.submenu) {
      return checkSubmenuContainsActiveHref(link.submenu, activeHref);
    } else return false;
  });

  const { assetFormHasChanges, setShowCancelConfirmationDialog } =
    useContext(AssetManagementContext);
  const navigate = useNavigate();

  const linkIsSelected = checkLinkIsSelected(link, activeHref);

  function handleLinkClick(href: string) {
    // prevent navigation if assetform has changes,
    // otherwise programatically navigate to desired page.
    if (assetFormHasChanges) {
      setShowCancelConfirmationDialog(true);
    } else {
      // DO NOT REMOVE THESE LINES OR NAVIGATION WONT WORK.
      setActiveHref(href);
      navigate(href);
      closeMobileMenu && closeMobileMenu();
    }
  }

  function handleSubmenuParentClick(e: any, subMenu?: navItemsType[]) {
    if (navIsCollapsed) {
      // open nav and always expand submenu if nav collapsed
      const bodyEl = document.querySelector('body');
      bodyEl?.classList.remove('--nav-collapsed');

      setNavIsCollapsed(false);
      setUserHasCollapsed(false);
      setSubMenuOpen(true);
    }
    if (assetFormHasChanges) {
      setShowCancelConfirmationDialog(true);
    } else if (subMenu) {
      // default to selecting first submenu child
      const activeSubMenues = subMenu.filter(link => !link.hidden)
      if (activeSubMenues.length > 0) {
        setActiveHref(activeSubMenues[0].href);
        navigate(activeSubMenues[0].href);
        setsubmenuParentClickTimestamp(e.timeStamp);
      }
    }
  }

  useEffect(() => {
    // open relevant submenu when  submenu parent is clicked
    if (link.submenu) {
      const submenuContainsActiveHref = checkSubmenuContainsActiveHref(link.submenu, activeHref);

      if (submenuContainsActiveHref && !subMenuOpen) {
        setSubMenuOpen(true);
      } else if (submenuContainsActiveHref && subMenuOpen) {
        setSubMenuOpen(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submenuParentClickTimestamp]);

  useEffect(() => {
    //handle submenu toggle when a link is clicked.
    if (link.submenu) {
      const submenuContainsActiveHref = checkSubmenuContainsActiveHref(link.submenu, activeHref);
      setSubMenuOpen(submenuContainsActiveHref);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeHref]);

  useEffect(() => {
    //handle submenu toggle when nav colapse toggles
    if (link.submenu) {
      const submenuContainsActiveHref = checkSubmenuContainsActiveHref(link.submenu, activeHref);
      setSubMenuOpen(submenuContainsActiveHref);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navIsCollapsed]);

  return link.submenu ? (
    <ComponentUserPermissionRenderWrapper userPermissions={link.userPermissions}>
      <ComponentFeaturePermissionRenderWrapper featurePermissions={link.featurePermissions}>
        <StyledNavLinkWithSubItem>
          <StyledNavMenuButton
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                handleSubmenuParentClick(e, link.submenu);
              }
            }}
            onClick={(e) => {
              handleSubmenuParentClick(e, link.submenu);
            }}
            id={link.href.split('/')[1]}
          >
            {checkSubmenuContainsActiveHref(link.submenu, activeHref) && <Indicator />}
            <Tooltip
              placement="right"
              title={link.name}
              overlayStyle={!navIsCollapsed ? { display: 'none' } : {}} // dont display tooltip if open.
            >
              <span
                className={`${checkSubmenuContainsActiveHref(link.submenu, activeHref) ? '--highlight' : ''
                  }`}
              >
                <link.icon />
              </span>
            </Tooltip>
            {!navIsCollapsed && link.name}
            <img
              src={rightArrowIcon}
              alt="arrow"
              className="arrow"
              style={{
                transform: subMenuOpen && !navIsCollapsed ? 'rotate(90deg)' : 'rotate(0deg)',
              }}
            />
          </StyledNavMenuButton>

          {subMenuOpen && (
            <ul className="submenu-parent" style={{ height: navIsCollapsed ? '0' : 'initial' }}>
              {link.submenu.filter(link => !link.hidden).map((submenuItem) => {
                const submenuLinkIsSelected = checkLinkIsSelected(submenuItem, activeHref);

                return (
                  <ComponentUserPermissionRenderWrapper
                    userPermissions={link.userPermissions}
                    key={submenuItem.href}
                  >
                    <ComponentFeaturePermissionRenderWrapper
                      featurePermissions={link.featurePermissions}
                    >
                      <li>
                        <StyledNavMenuButton
                          onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                              handleLinkClick(submenuItem.href);
                            }
                          }}
                          onClick={() => {
                            handleLinkClick(submenuItem.href);
                          }}
                          className={`submenu-button ${submenuLinkIsSelected ? 'selected' : ''}`}
                          id={submenuItem.href.split('/')[1]}
                        >
                          <Tooltip
                            placement="right"
                            title={submenuItem.name}
                            overlayStyle={!navIsCollapsed ? { display: 'none' } : {}} // dont display tooltip if open.
                          ></Tooltip>
                          {!navIsCollapsed && submenuItem.name}
                        </StyledNavMenuButton>
                      </li>
                    </ComponentFeaturePermissionRenderWrapper>
                  </ComponentUserPermissionRenderWrapper>
                );
              })}
            </ul>
          )}
        </StyledNavLinkWithSubItem>
      </ComponentFeaturePermissionRenderWrapper>
    </ComponentUserPermissionRenderWrapper>
  ) : (
    <ComponentUserPermissionRenderWrapper userPermissions={link.userPermissions}>
      <ComponentFeaturePermissionRenderWrapper featurePermissions={link.featurePermissions}>
        <StyledNavLinkItem>
          <StyledNavMenuButton
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                handleLinkClick(link.href);
              }
            }}
            onClick={() => {
              handleLinkClick(link.href);
            }}
            className={linkIsSelected ? 'selected' : ''}
            id={link.href.split('/')[1]}
          >
            {linkIsSelected && <Indicator />}
            <Tooltip
              placement="right"
              title={link.name}
              overlayStyle={!navIsCollapsed ? { display: 'none' } : {}} // dont display tooltip if open.
            >
              <span>
                <link.icon />
              </span>
            </Tooltip>
            {!navIsCollapsed && link.name}
          </StyledNavMenuButton>
        </StyledNavLinkItem>
      </ComponentFeaturePermissionRenderWrapper>
    </ComponentUserPermissionRenderWrapper>
  );
}
