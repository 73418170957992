import React, { useContext } from 'react';
import { getSortedArrayByValue } from 'utils/utils';
import { AssetConfigContext } from 'contexts/assetConfig.context';
import { MultiSelect } from 'components/inputs/Select/MultiSelect';
import { LabelValuePair } from 'utils/common';

type AssetOwnerProps = {
  value: string[];
  onChange: (values: string[]) => void;
  isDisabled?: boolean;
  className?: string;
};

export const MultiSelectAssetOwners = React.memo((props: AssetOwnerProps) => {
  const { assetOwners } = useContext(AssetConfigContext);
  const alphaSortedOptions = getSortedArrayByValue(assetOwners, 'value')
    .map((r: LabelValuePair) => ({ key: r.label, value: r.value }));;

  return (
    <MultiSelect
      label="Owners"
      id="asset_owners_select"
      options={alphaSortedOptions}
      value={props.value}
      onChange={props.onChange}
      className={props.className}
    />
  );
});
