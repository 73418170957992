import Button from "components/Button/Button";
import { GroupSection } from "components/GroupSection";
import { Input } from "components/inputs/Input/Input";
import { MultiSelect } from "components/inputs/Select/MultiSelect";
import { SearchableSelect } from "components/inputs/Select/SearchableSelect";
import { Select } from "components/inputs/Select/Select";
import { Toggle } from "components/inputs/Toggle/Toggle";
import { useRoleStore } from "contexts/RootStore.context";
import { Content, Horizontal, StretchSpacer, Vertical } from "gls/lib";
import useDevice from "hooks/useDevice";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { configRoutePath } from "router/routePaths";
import { formStyle } from "styling/config.style";
import { style } from "typestyle";
import { RequiredValidationError } from "utils/formstate";
import FormHeader from "views/AssetManagementPage/components/FormHeader";
import { MultiSelectAssetTypes } from "views/config/components/MultiSelectAssetTypes";
import { MultiSelectAssetOwners } from "views/config/components/MultiSelectOwners";
import { AdminPermissions, AssetPermissions, PeoplePermissions, SecurityPermissions } from "../RoleConfigDto";

type RoleFormProps = {
  roleId?: string;
  canManageRoles: boolean;
  saveHandler: () => void;
}

export const RoleForm = observer((props: RoleFormProps) => {

  const {
    allRoles,
    workgroups,
    roleForm,
    saveLoading,
    isAnyChange,
  } = useRoleStore();

  const { dimension } = useDevice();
  const navigate = useNavigate();

  return (
    <Vertical
      spacing={20}
      style={{
        height: dimension.height - 90,
        overflowY: "auto",
        paddingTop: 34,
        paddingRight: 4,
      }}>

      <FormHeader text="ROLE INFORMATION" bold />
      <Horizontal spacing={40} className={formStyle} padding={30}>
        <SearchableSelect
          label="Workgroup Name:"
          className={fieldStyle}
          required
          disabled={!!props.roleId || !props.canManageRoles}
          key="workgroup-select"
          id="workgroup-select"
          name="workgroup-select"
          options={workgroups}
          onChange={(value) => {
            roleForm.$.workgroupId.onChange(value)
            roleForm.$.PeopleRoles.onChange([])
          }}
          value={roleForm.$.workgroupId.value}
        />
        <Input
          label="Role Name:"
          required
          className={fieldStyle}
          value={roleForm.$.name.value}
          onChange={(value) => { roleForm.$.name.onChange(value) }}
          disabled={!props.canManageRoles}
          key="role-name-input"
          id="role-name-input"
          name="role-name-input"
          dataTestId="role-name-input"
          maxLength={30}
          error={roleForm.$.name.error !== RequiredValidationError ? roleForm.$.name.error : ""}
        />
      </Horizontal>

      <Vertical padding={30} spacing={20} className={formStyle}>
        <FormHeader text="SECURITY PERMISSIONS" bold />
        <GroupSection title="Access">
          <Toggle
            key="login_mobile_app-permission"
            id="login_mobile_app-permission"
            name="login_mobile_app-permission"
            disabled={!props.canManageRoles}
            value={!!roleForm.$.securityPermissions.value.find((item: string) => item === SecurityPermissions.access_mobile_app)}
            onChange={(value) => {
              if (value) {
                roleForm.$.securityPermissions.value.push(SecurityPermissions.access_mobile_app)
              }
              else {
                const newlist = roleForm.$.securityPermissions.value.filter(item => item !== SecurityPermissions.access_mobile_app)
                roleForm.$.securityPermissions.onChange(newlist);
              }
            }}
            label="Mobile App"
            labelPlace="right"
          />
          <Toggle
            key="login_portal_app-permission"
            id="login_portal_app-permission"
            name="login_portal_app-permission"
            disabled={!props.canManageRoles}
            value={!!roleForm.$.securityPermissions.value.find((item: string) => item === SecurityPermissions.access_portal)}
            onChange={(value) => {
              if (value) {
                roleForm.$.securityPermissions.value.push(SecurityPermissions.access_portal)
              }
              else {
                const newlist = roleForm.$.securityPermissions.value.filter(item => item !== SecurityPermissions.access_portal)
                roleForm.$.securityPermissions.onChange(newlist);
              }
            }}
            label="Portal"
            labelPlace="right"
          />
          <Toggle
            key="locate_person-permission"
            id="locate_person-permission"
            name="locate_person-permission"
            disabled={!props.canManageRoles}
            value={!!roleForm.$.securityPermissions.value.find((item: string) => item === SecurityPermissions.locate_this_person)}
            onChange={(value) => {
              if (value) {
                roleForm.$.securityPermissions.value.push(SecurityPermissions.locate_this_person)
              }
              else {
                const newlist = roleForm.$.securityPermissions.value.filter(item => item !== SecurityPermissions.locate_this_person)
                roleForm.$.securityPermissions.onChange(newlist);
              }
            }}
            label="Locate this person"
            labelPlace="right"
          />
        </GroupSection>
      </Vertical>

      <Vertical padding={30} spacing={20} className={formStyle}>
        <FormHeader text="ASSETS" bold />
        <Select
          id="asset-segment"
          label="Asset segment"
          options={[{ key: "Type", value: "type" }, { key: "Owner", value: "owner" }]}
          value={roleForm.$.assetSegment.value}
          onChange={roleForm.$.assetSegment.onChange}
        />
        {roleForm.$.assetSegment.value === "type" && (
          <MultiSelectAssetTypes
            className={multiSelectStyle}
            value={roleForm.$.assetTypes.value || []}
            onChange={roleForm.$.assetTypes.onChange} />
        )}
        {roleForm.$.assetSegment.value === "owner" && (
          <MultiSelectAssetOwners
            className={multiSelectStyle}
            value={roleForm.$.owners.value || []}
            onChange={roleForm.$.owners.onChange} />
        )}
        <GroupSection title="Asset Permissions">
          <Toggle
            key="view-assetpermission"
            id="view-assetpermission"
            name="view-assetpermission"
            disabled={!props.canManageRoles}
            value={!!roleForm.$.assetPermissions.value.find((item: string) => item === AssetPermissions.view)}
            onChange={(value) => {
              if (value) {
                roleForm.$.assetPermissions.value.push(AssetPermissions.view)
              }
              else {
                const newlist = roleForm.$.assetPermissions.value.filter(item => item !== AssetPermissions.view)
                roleForm.$.assetPermissions.onChange(newlist);
              }
            }}
            label="View"
            labelPlace="right"
          />
          <Toggle
            key="create-assetpermission"
            id="create-assetpermission"
            name="create-assetpermission"
            disabled={!props.canManageRoles}
            value={!!roleForm.$.assetPermissions.value.find((item: string) => item === AssetPermissions.manage)}
            onChange={(value) => {
              if (value) {
                roleForm.$.assetPermissions.value.push(AssetPermissions.manage)
              }
              else {
                const newlist = roleForm.$.assetPermissions.value.filter(item => item !== AssetPermissions.manage)
                roleForm.$.assetPermissions.onChange(newlist);
              }
            }}
            label="Create and Manage"
            labelPlace="right"
          />
          <Toggle
            key="report-assetpermission"
            id="report-assetpermission"
            name="report-assetpermission"
            disabled={!props.canManageRoles}
            value={!!roleForm.$.assetPermissions.value.find((item: string) => item === AssetPermissions.reports)}
            onChange={(value) => {
              if (value) {
                roleForm.$.assetPermissions.value.push(AssetPermissions.reports)
              }
              else {
                const newlist = roleForm.$.assetPermissions.value.filter(item => item !== AssetPermissions.reports)
                roleForm.$.assetPermissions.onChange(newlist);
              }
            }}
            label="Reports/Logs"
            labelPlace="right"
          />
        </GroupSection>
      </Vertical >

      <Vertical padding={30} spacing={20} className={formStyle}>
        <FormHeader text="PEOPLE" bold />
        <MultiSelect
          id="people-roles-select"
          label="Roles"
          options={allRoles
            .filter(role => role.workgroup.id === roleForm.$.workgroupId.value)
            .filter(role => role.id !== props.roleId)
            .map(role => ({ value: role.id, key: role.name }))
          }
          className={multiSelectStyle}
          value={roleForm.$.PeopleRoles.value}
          onChange={roleForm.$.PeopleRoles.onChange} />
        <GroupSection title="People Permissions">
          <Toggle
            key="view-peoplepermission"
            id="view-peoplepermission"
            name="view-peoplepermission"
            disabled={!props.canManageRoles}
            value={!!roleForm.$.peoplePermissions.value.find((item: string) => item === PeoplePermissions.view)}
            onChange={(value) => {
              if (value) {
                roleForm.$.peoplePermissions.value.push(PeoplePermissions.view)
              }
              else {
                const newlist = roleForm.$.peoplePermissions.value.filter(item => item !== PeoplePermissions.view)
                roleForm.$.peoplePermissions.onChange(newlist);
              }
            }}
            label="View"
            labelPlace="right"
          />
          <Toggle
            key="report-peoplepermission"
            id="report-peoplepermission"
            name="report-peoplepermission"
            disabled={!props.canManageRoles}
            value={!!roleForm.$.peoplePermissions.value.find((item: string) => item === PeoplePermissions.reports)}
            onChange={(value) => {
              if (value) {
                roleForm.$.peoplePermissions.value.push(PeoplePermissions.reports)
              }
              else {
                const newlist = roleForm.$.peoplePermissions.value.filter(item => item !== PeoplePermissions.reports)
                roleForm.$.peoplePermissions.onChange(newlist);
              }
            }}
            label="Reports/Logs"
            labelPlace="right"
          />
        </GroupSection>
      </Vertical>

      <Vertical padding={30} spacing={20} className={formStyle}>
        <FormHeader text="WORKGROUP ADMIN PERMISSIONS" bold />
        <GroupSection title="Roles">
          <Toggle
            key="create-adminpermission"
            id="create-adminpermission"
            name="create-adminpermission"
            disabled={!props.canManageRoles}
            value={!!roleForm.$.adminPermissions.value.find((item: string) => item === AdminPermissions.create_and_manage_roles)}
            onChange={(value) => {
              if (value) {
                roleForm.$.adminPermissions.value.push(AdminPermissions.create_and_manage_roles)
              }
              else {
                const newlist = roleForm.$.adminPermissions.value.filter(item => item !== AdminPermissions.create_and_manage_roles)
                roleForm.$.adminPermissions.onChange(newlist);
              }
            }}
            label="Create and Manage Roles"
            labelPlace="right"
          />
          <Toggle
            key="view-adminpermission"
            id="view-adminpermission"
            name="view-adminpermission"
            disabled={!props.canManageRoles}
            value={!!roleForm.$.adminPermissions.value.find((item: string) => item === AdminPermissions.view_roles)}
            onChange={(value) => {
              if (value) {
                roleForm.$.adminPermissions.value.push(AdminPermissions.view_roles)
              }
              else {
                const newlist = roleForm.$.adminPermissions.value.filter(item => item !== AdminPermissions.view_roles)
                roleForm.$.adminPermissions.onChange(newlist);
              }
            }}
            label="View Roles"
            labelPlace="right"
          />
        </GroupSection>
      </Vertical>

      {
        props.canManageRoles && (
          <Content padding={[4, 0, 0, 0]} horizontalAlign="right">
            <Horizontal>
              {props.roleId &&
                <Button
                  outline
                  onClick={() => { navigate(configRoutePath.listRoles) }}
                  disabled={saveLoading}
                >
                  Cancel
                </Button>
              }
              <Button
                onClick={props.saveHandler}
                disabled={!!(roleForm.hasError || (props.roleId && !isAnyChange))}
                isLoading={saveLoading}
                id="save-button"
              >
                {props.roleId ? "Save" : "Create"}
              </Button>

            </Horizontal>
          </Content>
        )
      }
      <StretchSpacer className={style({ paddingBottom: 24 })} />
    </Vertical >
  );
});


const fieldStyle = style({
  maxWidth: "350px",
  minWidth: "350px",
});

const multiSelectStyle = style({
  maxWidth: "100% !important",
})