import { min } from "lodash";
import { useState } from "react";
import { TableInstance } from "react-table";
import { classes } from "typestyle";
import { DataTableStyle } from "../style";
import { GridLoading } from "./GridLoading";
import { Portal } from "components/Portal";

type DataGridPaginationProps = {
    table: TableInstance,
    previousPageValidation: boolean,
    nextPageValidation: boolean,
    nonInteractivePageNumbers?: boolean;
}
export const DataGridPagination = ({ table, previousPageValidation, nextPageValidation, nonInteractivePageNumbers = false }: DataGridPaginationProps) => {

    const [showLoading, setShowLoading] = useState(false)

    const gotoPageHandler = (pageNumber: number) => {
        setShowLoading(true)
        setTimeout(() => {
            table.gotoPage(pageNumber)
            setShowLoading(false)
        }, 100);
    }
    const nextPageHandler = () => {
        setShowLoading(true)
        setTimeout(() => {
            table.nextPage()
            setShowLoading(false)
        }, 100);
    }
    const previousPageHandler = () => {
        setShowLoading(true)
        setTimeout(() => {
            table.previousPage()
            setShowLoading(false)
        }, 100);
    }
    const pageBullets = [];
    let startPage = min([table.state.pageIndex - 4, table.pageOptions.length - 9]) || 0;
    startPage = startPage < 0 ? 0 : startPage;
    for (let i = 1; i <= (min([9, table.pageOptions.length]) || 0); i++) {
        pageBullets.push(
            <PageBullet
                key={`paging-bullet-${i + startPage}`}
                table={table}
                value={i + startPage}
                nonInteractivePageNumbers={nonInteractivePageNumbers}
                onClick={gotoPageHandler}
                className={(table.state.pageIndex + 1) === i + startPage ? "currentPage" : ""}
            />)
    }
    return (
        <>
            <Portal>
                <GridLoading show={showLoading} />
            </Portal>
            <div className={DataTableStyle.pagination}>
                {table.canPreviousPage && previousPageValidation &&
                    <div className={classes("changePageButton", "enablePageButton")} onClick={() => previousPageHandler()} >
                        {'<'}
                    </div>
                }
                {pageBullets}
                {table.pageOptions.length > 1 &&
                    <div className={classes("changePageButton",
                        (table.canNextPage && nextPageValidation) ? "enablePageButton" : "disablePageButton")}
                        onClick={() => (table.canNextPage && nextPageValidation) ? nextPageHandler() : ""} >
                        {'>'}
                    </div>
                }
            </div>
        </>
    )
}

type PageBulletProps = {
    table: TableInstance,
    value: number,
    className: string,
    onClick: (pageNumber: number) => void,
    nonInteractivePageNumbers: boolean,
}
const PageBullet = ({ table, value, className, onClick, nonInteractivePageNumbers }: PageBulletProps) => {

    return (
        <div
            className={classes("pageBullet", className, !nonInteractivePageNumbers ? "enable" : "")}
            onClick={() => {
                if (!nonInteractivePageNumbers) {
                    onClick(value - 1);
                }
            }}
        >
            {value}
        </div>
    )
}