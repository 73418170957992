import React, { useEffect, useMemo } from 'react';
import Button from 'components/Button/Button';
import config from 'react-global-configuration';
import { clearLocalStorageAndCookies } from 'utils/utils';
import { signInWithRedirect } from 'aws-amplify/auth';
import { Amplify } from 'aws-amplify';
import { LocalAuth } from 'utils/_localAuth';
import { useNavigate } from 'react-router-dom';

export default function SignoutPage() {

  const navigate = useNavigate();
  const amplify: any = useMemo(() => {
    if (config.get().SHOULD_USE_COGNITO) {
      return Amplify;
    } else {
      return new LocalAuth(navigate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSignIn() {
    clearLocalStorageAndCookies();
    if (config.get().SHOULD_USE_COGNITO) {
      signInWithRedirect();
    } else {
      amplify.signInWithRedirect()
    }

  }

  useEffect(() => {
    clearLocalStorageAndCookies();
    console.log('user signed out');
  }, []);

  return (
    <main className="u-flex-center">
      <div className="container">
        <h1>Your session has expired.</h1>
        <p>Please sign in again.</p>
        <br />
        <Button onClick={handleSignIn}>Sign In</Button>
      </div>
    </main>
  );
}
