import { classes } from "typestyle";
import { DataTableStyle } from "../style";

type GridLoadingProps = {
    show: boolean;
    id?: string;
}
export const GridLoading = ({ id, show }: GridLoadingProps) => {

    return (
        <div
            className={classes(DataTableStyle.loading, show ? DataTableStyle.showLoading : DataTableStyle.hideLoading)}>
            <div className={DataTableStyle.loadingIcon}></div>
        </div>
    )
}