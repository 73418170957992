/*
RoleStore class handles all states, API calls, form filling and form validation,
  and logic of Add and Edit role records.
*/

import { CreateRole, GetRole, getRoles, UpdateRole } from "api/config/roles";
import { getWorkgroups } from "api/config/workgroup";
import { FieldState, FormState } from "formstate";
import { action, computed, makeObservable, observable } from "mobx";
import { RootStore } from "stores/RootStore";
import { KeyValuePair } from "utils/common";
import { required } from "utils/formstate";
import { isDeepEqual } from "utils/utils";
import { RoleConfigDto, RoleListItem } from "views/config/roles/RoleConfigDto";

export class RoleStore {
  root: RootStore;
  version: string = "";
  dataLoading = false;
  dataLoadingError: boolean = false;
  workgroups: KeyValuePair[] = [];
  allRoles: RoleListItem[] = []
  currentRoleId: string | undefined = undefined;
  currentRole: RoleListItem = new RoleListItem();
  roleForm = this.createRoleForm();
  saveLoading: boolean = false;

  constructor(root: RootStore) {
    this.root = root;

    makeObservable(this, {
      dataLoading: observable,
      saveLoading: observable,
      dataLoadingError: observable,
      workgroups: observable,
      roleForm: observable,
      currentRoleId: observable,
      allRoles: observable,

      LoadData: action.bound,
      reset: action.bound,
      SaveRole: action.bound,

      getRoles: computed,
      isAnyChange: computed,
    });
  }

  setCurrentRoleId = (roleId: string | undefined) => {
    this.currentRoleId = roleId;
  }

  createRoleForm() {
    return new FormState({
      workgroupId: new FieldState(this.currentRole?.workgroup.id).validators(required),
      name: new FieldState(this.currentRole.name).validators(required, (value) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        if (this.allRoles.some(role => role.id !== this.currentRoleId
          && role.name.toLocaleLowerCase() === value.toLocaleLowerCase()))
          return "A role with the name specified already exists."
      }),
      assetSegment: new FieldState(this.currentRole.asset_options_by_owner
        && this.currentRole.asset_options_by_owner.owners.length > 0 ? "owner" : "type"),
      owners: new FieldState<string[]>(this.currentRole.asset_options_by_owner.owners.map(item => item.id)),
      assetTypes: new FieldState<string[]>(this.currentRole.asset_options_by_type.types.map(item => item.id)),
      assetPermissions: new FieldState(this.currentRole.asset_options_by_owner
        && this.currentRole.asset_options_by_owner.owners.length > 0
        ? this.currentRole.asset_options_by_owner.permissions
        : this.currentRole.asset_options_by_type.permissions),
      PeopleRoles: new FieldState(this.currentRole.people_options.roles),
      peoplePermissions: new FieldState(this.currentRole.people_options.permissions),
      adminPermissions: new FieldState(this.currentRole.workgroup_admin_permissions || []),
      securityPermissions: new FieldState(this.currentRole.security_permissions || []),
    });
  }

  fillForm(data: RoleListItem | null) {
    if (data) {
      this.currentRole = data;
    }
    this.roleForm = this.createRoleForm();
    this.roleForm.validate();
  }

  reset() {
    this.currentRole = new RoleListItem();
    this.roleForm = this.createRoleForm();
  }

  LoadData(roleId: string | null = null) {
    this.dataLoading = true;
    return Promise.all([
      getRoles(),
      getWorkgroups(),
      roleId ? GetRole(roleId) : Promise.resolve(null),
    ])
      .then(res => {
        this.allRoles = res[0];
        this.workgroups = res[1].map(obj => ({ key: obj.name, value: obj.id }))
        this.fillForm(res[2]);
      })
      .catch(() => {
        this.dataLoadingError = true;
      })
      .finally(() => {
        this.dataLoading = false;
      })
  }

  BuildRoleConfigDto() {
    const obj = new RoleConfigDto();
    obj.role.id = this.currentRole.id;
    obj.role.name = this.roleForm.$.name.value;
    obj.role.workgroup_id = this.roleForm.$.workgroupId.value;
    obj.role.security_permissions = this.roleForm.$.securityPermissions.value.length > 0
      ? this.roleForm.$.securityPermissions.value : null;
    obj.role.asset_options_by_type = {
      segment: "type",
      types: this.roleForm.$.assetSegment.value === "type"
        ? this.roleForm.$.assetTypes.value.map(item => ({ id: item, name: '' }))
        : [],
      permissions: this.roleForm.$.assetSegment.value === "type"
        ? this.roleForm.$.assetPermissions.value
        : []
    }

    obj.role.asset_options_by_owner = {
      segment: "owner",
      owners: this.roleForm.$.assetSegment.value === "owner"
        ? this.roleForm.$.owners.value.map(item => ({ id: item, name: '' }))
        : [],
      permissions: this.roleForm.$.assetSegment.value === "owner"
        ? this.roleForm.$.assetPermissions.value
        : []
    }

    obj.role.people_options = {
      roles: this.roleForm.$.PeopleRoles.value,
      permissions: this.roleForm.$.peoplePermissions.value
    }
    obj.role.workgroup_admin_permissions = this.roleForm.$.adminPermissions.value.length > 0
      ? this.roleForm.$.adminPermissions.value : null;

    return obj.role;
  }

  async SaveRole(roleId: string | undefined) {
    this.saveLoading = true;
    const promise = roleId
      ? UpdateRole(roleId, this.BuildRoleConfigDto())
      : CreateRole(this.BuildRoleConfigDto())
    return promise
      .catch((error) => Promise.reject(error))
      .finally(() => this.saveLoading = false)
  }

  get getRoles() {
    if (!this.roleForm.$.workgroupId.value)
      return [];

    return this.allRoles;
  }

  get isAnyChange() {
    const srcRole = this.currentRole;
    const finalRole = this.BuildRoleConfigDto();
    const debug = false;
    //............... always return true for create mode .....................
    if (!this.currentRoleId) {
      return false;
    }
    if (!isDeepEqual(srcRole, finalRole,
      ["workgroup", "workgroup_id", "asset_options_by_owner", "asset_options_by_type"], debug)) {
      return true;
    }
    if (!isDeepEqual(srcRole.asset_options_by_type, finalRole.asset_options_by_type,
      ["name", "is_enabled"], debug)) {
      return true;
    }
    if (!isDeepEqual(srcRole.asset_options_by_owner, finalRole.asset_options_by_owner,
      ["name", "is_enabled"], debug)) {
      return true;
    }
    return false;
  }
}
