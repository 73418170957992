import {
  genericDelete,
  genericGet,
  genericPut,
} from "api/config/base";
import { getConfigApiUrl } from "utils/utils";
import {
  AdminPermissionsDto,
  SystemAdminPermission,
  SystemAdminUserDto
} from "views/config/SystemAdministrators/AdminPermissionsDto";

export function getUser(id: string): Promise<SystemAdminUserDto> {
  return genericGet<SystemAdminUserDto>(`${getConfigApiUrl()}/v1/user/${id}`)
}

export function getIsSystemAdmin(): Promise<boolean> {
  return genericGet<boolean>(`${getConfigApiUrl()}/v1/user/system_admin`)
}

export function GetLoggedInUserSystemAdminPermissionsRequest(): Promise<SystemAdminPermission[]> {
  return genericGet<Array<SystemAdminPermission>>(
    `${getConfigApiUrl()}/v1/user/system_admin_permissions`
  )
}

export function getSystemAdmins(): Promise<SystemAdminUserDto[]> {
  return genericGet<SystemAdminUserDto[]>(`${getConfigApiUrl()}/v1/user/system_admins`)
}

export function getNonSystemAdmins(): Promise<Array<SystemAdminUserDto>> {
  return genericGet<Array<SystemAdminUserDto>>(`${getConfigApiUrl()}/v1/users/not_system_admins`)
}

export function getUsers(): Promise<Array<SystemAdminUserDto>> {
  return genericGet<Array<SystemAdminUserDto>>(`${getConfigApiUrl()}/v1/users`)
}

export function getUserAdminPermissions(userId: string): Promise<SystemAdminPermission[]> {
  return genericGet<Array<SystemAdminPermission>>(
    `${getConfigApiUrl()}/v1/user/${userId}/system_admin_permissions`
  )
}


export function postUpdateSystemAdminPermissions(
  systemAdminPermissions: AdminPermissionsDto
) {
  return genericPut<boolean, AdminPermissionsDto>(
    `${getConfigApiUrl()}/v1/users/system_admin_permissions`,
    systemAdminPermissions
  );
}

export function deleteRemoveSystemAdmin(
  userId: string
): Promise<boolean> {
  return genericDelete(
    `${getConfigApiUrl()}/v1/user/${userId}/system_admin_permissions`
  );
}
