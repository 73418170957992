import { colours } from "styling/colours";
import { stylesheet } from "typestyle";

const borderColor = "#DADBDE";
const editingRowColor = "#ECF5FA";

export const DataTableStyle = stylesheet({
  container: {
    position: "relative",

    $nest: {
      "::-webkit-scrollbar": {
        width: "7px",
        height: "9px",
      },
      "::-webkit-scrollbar-track": {
        backgroundColor: "rgba(205, 208, 227, 0)",
      },
      "::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(188, 190, 192, 0.5)",
        width: "7px",
        height: "9px",
        borderRadius: "14px",
       
      },
    }
  },
  table: {
    width: "100%",
    backgroundColor: "white",
    scrollbarColor: '#DDDEDF white',
    position: "relative",
    borderCollapse: 'collapse',
    overflow: 'scroll',
    "-webkit-transform": 'translateZ(0)',

    $nest: {
      "& .header": {
        position: 'sticky',
        zIndex: 1,
        width: "100%",
        display: "flex",
        top: 0,
      },
      "& .footer": {
        position: 'sticky',
        zIndex: 1,
        width: "100%",
        display: "flex",
        minHeight: "50px",
        bottom: 0,
      },
      "& .body": {
        position: 'relative',
        display: 'inline-table',
        zIndex: 0,
        width: "100%",
      },
      "&[data-sticky-td]": {
        position: 'sticky',
      },
      "& .scrollable__value-container": {
        flexWrap: "nowrap"
      },
    }
  },
  emptyBody: {
    width: "100%",
    height: "50px",
    paddingTop: "17px",
    textAlign: "center",
  },
  loading: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: 10000,
    backgroundColor: '#eee9',
    backgroundClip: "content-box",
    color: 'white',
    textAlign: 'center',
    paddingTop: '50px',
  },
  hideLoading: {
    visibility: "hidden"
  },
  showLoading: {
    visibility: "visible"
  },
  loadingIcon: {
    width: '48px',
    height: '48px',
    marginTop: "20px",
    border: '5px solid #91DBF6',
    borderBottomColor: 'transparent',
    position: "absolute",
    top: "40%",
    borderRadius: '50%',
    display: 'inline-block',
    boxSizing: 'border-box',
    animation: 'rotation 1s linear infinite',
    verticalAlign: 'middle',
  },
  headertr: {
    width: "fit-content",
    $nest: {
      "&:first-child": {
        borderTop: `1px solid ${borderColor}`,
      },
    }
  },
  bodytr: {
    $nest: {
      "&:nth-of-type(2n)": {
        $nest: {
          "&>*": {
            backgroundColor: "#F6F7F9"
          }
        }
      },
      "&:hover": {
        $nest: {
          "&>*": {
            //backgroundColor: "red"
          }
        }
      },
      "& .editing": {
        backgroundColor: editingRowColor,
        padding: '0',
        $nest: {
          "& .cell-container": {
            position: 'relative',
            height: "50px",
            $nest: {
              "&:focus-within": {
                backgroundColor: colours.white,
                borderRadius: 0,
                border: `1px solid ${colours.highlighter}`,
              },
            }
          },
          "& input:not([type=checkbox])": {
            backgroundColor: editingRowColor,
            $nest: {
              '&:focus': {
                borderWidth: 0,
                backgroundColor: colours.white,
              },
            }
          },
          '& .scrollable__control': {
            backgroundColor: editingRowColor,
            borderRadius: 0,
            $nest: {
              "&:focus-within": {
                backgroundColor: colours.white,
                borderWidth: 0,
              },
            }
          },
        }
      },
    },
  },
  footertr: {
    width: "fit-content",
  },
  th: {
    borderBottom: `1px solid ${borderColor}`,
    borderRight: `1px solid ${borderColor}`,
    padding: "18px 4px",
    backgroundColor: '#EEEFF1',
    minWidth: '150px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    color: '#0055B8',
    overflow: 'hidden',
    $nest: {
      '&:first-child': {
        borderLeft: `1px solid ${borderColor}`,
      },
      '&:last-child': {
        borderLeft: `1px solid ${borderColor}`,
      },
      '&:nth-last-child(2)': {
        borderRight: `0px`,
      },
      "& .resizer": {
        display: 'inline-block',
        width: '5px',
        height: '100%',
        position: 'absolute',
        right: 0,
        top: 0,
        transform: 'translateX(50%)',
        zIndex: 1,

        $nest: {
          '& .isResizing': {
            background: 'red',
          },
        }
      }
    }
  },
  td: {
    backgroundColor: 'white',
    borderBottom: `1px solid ${borderColor}`,
    borderRight: `1px solid ${borderColor}`,
    paddingLeft: "4px",
    paddingRight: "4px",
    overflow: 'hidden',
    minWidth: '150px',
    textOverflow: "ellipsis",
    verticalAlign: "middle",
    // lineHeight: "50px",
    whiteSpace: "nowrap",
    $nest: {
      '& .reat-table-cell-text': {
        paddingTop: "14px",
        paddingBottom: "14px",
        lineHeight: "22px",
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      },
      "& .reat-table-cell-text-wrap": {
        paddingTop: "14px",
        paddingBottom: "14px",
        lineHeight: "22px",
        whiteSpace: 'break-spaces',
      },
      '&:first-child': {
        borderLeft: `1px solid ${borderColor}`,
      },
      '&:last-child': {
        borderLeft: `1px solid ${borderColor}`,
      },
      '&:nth-last-child(2)': {
        borderRight: `0px`,
      },
      '& input': {
        borderWidth: "0px",
        height: "48px",
        $nest: {
          '&:focus': {
            borderWidth: "0px",
            backgroundColor: colours.white,
            height: "48px",
          }
        }
      },
      '& .scrollable__control': {
        borderWidth: "0px",
        lineHeight: "45px",
        $nest: {
          "& .scrollable__value-container": {
            height: "45px",
            $nest: {
              "& > div": {
                height: "100%"
              }
            }
          }
        }
      },
    }
  },
  required: {
    color: 'red',
    position: 'absolute',
    top: '4px',
    right: '4px',
    lineHeight: '10px',
  },
  cellError: {
    border: '1px solid red;',
    height: "100%",
    $nest: {
      '& input:not([type=checkbox])': {
        backgroundColor: "#FFF6F4 !important",
        paddingLeft: "30px",
        $nest: {
          '&:focus': {
            paddingLeft: "16px"
          }
        }
      },
      '& .scrollable__control': {
        minHeight: "47px",
        height: "47px",
        backgroundColor: "#FFF6F4 !important",
        paddingLeft: '10px',
        $nest: {
          "& input": {
            opacity: "0 !important"
          }
        }
      },
      '& .show': {
        visibility: "visible"
      },
      '& .hide': {
        visibility: "hidden"
      },
    }
  },
  iconError: {
    position: 'absolute',
    top: '17px',
    left: '8px',
  },
  addRow: {
    width: '100%',
    backgroundColor: "#F8F9FB",
    padding: '15px 0 5px 0',
    textAlign: 'right',
    $nest: {
      '& .content': {
        color: colours.secondary,
        marginRight: '3px',
        $nest: {
          '& svg': {
            cursor: 'pointer',
            verticalAlign: 'text-top',
            marginRight: '6px',
          }
        }
      },
      "& input": {
        backgroundColor: editingRowColor,
      },
      '& .disabled': {
        color: colours.tertiaryText,
      }
    }
  },
  paginationContainer: {
    display: "inline-grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    marginTop: "14px",
    width: "100%",
    $nest: {
      "& .next": {
        $nest: {
          "& > div": {
            float: "right"
          }
        }
      },
      "& > div": {
        display: "inline-block"
      },
    }
  },
  pagination: {
    marginTop: "15px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    $nest: {
      "& .changePageButton": {
        width: "25px",
        height: "25px",
        lineHeight: "25px",

      },
      "& .disablePageButton": {
        color: colours.tertiaryText
      },
      "& .enablePageButton": {
        cursor: "pointer",
      },
      "& .pageBullet": {
        width: "25px",
        height: "25px",
        lineHeight: "25px",
        fontSize: "15px",
        marginRight: "15px",
      },
      "& .currentPage": {
        borderRadius: "50%",
        backgroundColor: colours.secondaryText,
        color: "white",
      },
      "& .enable": {
        cursor: "pointer"
      }
    },
  },
});
