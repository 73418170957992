import { IdNamePair, KeyValuePair } from "utils/common";
import { WorkgroupDto } from "../workgroups/WorkgroupDto";

export enum SecurityPermissions {
  access_mobile_app = "access_mobile_app",
  access_portal = "access_portal",
  locate_this_person = "locate_this_person"
}

export enum AssetPermissions {
  view = "view",
  manage = "manage",
  reports = "reports"
}

export enum PeoplePermissions {
  view = "view",
  reports = "reports"
}

export enum AdminPermissions {
  view_roles = "view_roles",
  create_and_manage_roles = "create_and_manage_roles",
}

export class Role {
  id?: string = "";
  name: string = "";
  workgroup_id: string = "";
  asset_options_by_type: {
    segment: string,
    types: IdNamePair[],
    permissions: string[]
  } = {
      segment: "",
      types: [],
      permissions: []
    }
  asset_options_by_owner: {
    segment: string,
    owners: IdNamePair[],
    permissions: string[]
  } = {
      segment: "",
      owners: [],
      permissions: []
    }
  people_options: {
    roles: string[],
    permissions: string[]
  } = {
      roles: [],
      permissions: []
    }
  security_permissions: SecurityPermissions[] | null = null
  workgroup_admin_permissions: AdminPermissions[] | null = null
}

export class RoleConfigDto {
  role: Role = new Role()
}

export class RoleListItem extends Role {
  id: string = "";
  workgroup: WorkgroupDto = new WorkgroupDto()
}

export class accessedWorkgroupsDto {
  workgroups: KeyValuePair[] = [];
}