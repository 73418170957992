import Button from 'components/Button/Button';
import AntModalStyled from 'components/Modals/AntModalStyled';
import React from 'react';

export default function DownloadAssetsModal() {
  return (
    <AntModalStyled
      open
      closable={false}
      centered
      footer={null}
      maskClosable={false}
      style={{ maxHeight: '269px', height: '100%' }}
    >
      <header>
        <h3> Download in Progress</h3>
      </header>
      <p style={{ fontSize: '15px' }}>
        <br />
        The file is being prepared. Please do not navigate away from the page.
      </p>
      <footer>
        <Button isLoading={true} disabled={true}>
          Download
        </Button>
      </footer>
    </AntModalStyled>
  );
}
