import { useMemo, useState } from 'react';
import { Amplify } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import config from 'react-global-configuration';
import { LocalAuth } from 'utils/_localAuth';
import { signOut, fetchAuthSession } from 'aws-amplify/auth';

export type AuthStateTypes = {
  isMock?: boolean;
  isAuthenticated: boolean;
  userId: string;
  jwtToken: string | null;
  accessToken?: string;
  username: string;
};
export type AuthContextTypes = {
  authState: AuthStateTypes;
  setAuthState: any;
};

export const defaultAuthState = {
  isAuthenticated: false,
  userId: '',
  jwtToken: null,
  username: '',
};
//  use localstorage instead of cache, as cache wasnt working correctly with Cypress.
const authStateLocalStorage = JSON.parse(
  localStorage.getItem('aws-amplify-cacheauthState') || '{}',
);
// check if auth exists in localstorage first and use that, otherwise use default.
const initialAuthState = authStateLocalStorage.data ? authStateLocalStorage.data : defaultAuthState;

const useAuth = () => {
  const [authState, setAuthState] = useState<AuthStateTypes>(initialAuthState);
  const navigate = useNavigate();

  const amplify: any = useMemo(() => {
    if (config.get().SHOULD_USE_COGNITO) {
      return Amplify;
    } else {
      return new LocalAuth(navigate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const authSignout = async () => {
    if (config.get().SHOULD_USE_COGNITO) {
      await signOut().then(() => {
        setAuthState(defaultAuthState);
        navigate('/signout');
      });
    } else {
      amplify.currentAuthenticatedUser()
        .then(() => {
          amplify.signOut()
          setAuthState(defaultAuthState);
          navigate('/signout');
        })
        .catch(() => amplify.signInWithRedirect())
    }
  };

  async function getSession() {
    return await fetchAuthSession();
  }

  return {
    amplify,
    authState,
    defaultAuthState,
    setAuthState,
    authSignout,
    getSession,
  };
};

export default useAuth;
