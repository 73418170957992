import { Select } from "components/inputs/Select/Select";
import { RoleListItem } from "views/config/roles/RoleConfigDto";
import { WorkGroup } from "views/config/workgroups/WorkgroupDto";
import { Content, Horizontal } from "gls/lib";
import { colours } from "styling/colours";
import { style } from "typestyle";
import { formatNumericValue } from "utils/numeric";

type WorkgroupSelectProps = {
  roles: Record<string, RoleListItem[]>;
  workgroups: WorkGroup[];
  listLoading: boolean;
  dataLoadingError: boolean;
  selectedWorkgroup: string;
  changeWorkgroup: (workgroupId: string) => void;
};

export const WorkgroupSelect = ({
  roles,
  workgroups,
  listLoading,
  dataLoadingError,
  selectedWorkgroup,
  changeWorkgroup,
}: WorkgroupSelectProps) => {

  let result = null;

  if (dataLoadingError) {
    result = (
      <Select
        name={"WorkGroups"}
        onChange={() => { }}
        value={0}
        placeholder={"Could not fetch data"}
        options={[]}
        borderless
      />
    );
  } else if (listLoading) {
    result = (
      <Select
        name={"taskHandlersGroups"}
        onChange={() => { }}
        value={0}
        placeholder={"Fetching data..."}
        options={[]}
        borderless
      />
    );
  } else {
    result = (
      <Select
        name={"taskHandlersGroups"}
        id="workgroups-select"
        onChange={(workgroupId) => {
          changeWorkgroup(workgroupId);
        }}
        value={selectedWorkgroup}
        options={workgroups.map(workgroup => ({
          key: `${workgroup.name} (${formatNumericValue(roles[workgroup.id] ? roles[workgroup.id].length : 0)})`,
          value: workgroup.id,
        }))}
        borderless
      />
    );
  }

  return (
    <Horizontal>
      <Content className={workgroupLabelStyle}>Workgroup:</Content>
      {result}
    </Horizontal>
  );
};


const workgroupLabelStyle = style({
  lineHeight: "40px",
  color: colours.secondaryText,
  fontFamily: "MuseoSlab",
});
