import { icons } from "assets/icons";
import { colours } from "styling/colours";
import { style } from "typestyle";

export const ErrorBanner = ({ errorMsg }: { errorMsg: string }) => {
  return (
    <div className={LoadingErrorStyle}>
      <icons.ErrorBangIcon
        className={LoadingErrorBtnStyle}
        height={18}
        width={18}
        fill={colours.error}
      />
      <span>{errorMsg}</span>
    </div>
  )
}

const LoadingErrorStyle = style({
  margin: "10px 0 10px 0",
  padding: "10px",
  backgroundColor: colours.errorBackground,
  color: "#E64B38",
  fontSize: "12px",
  fontWeight: "normal",
  width: "fit-content"
});

const LoadingErrorBtnStyle = style({
  marginLeft: "5px",
  marginRight: "5px",
  verticalAlign: "bottom",
})

