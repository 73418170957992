import { icons } from 'assets/icons';
import React, { useMemo, useState } from 'react';
import logo from 'assets/images/navenio-landscape.svg';
import logoSmall from 'assets/images/navenio-small.svg';
import VersionReadout from './VersionReadout';
import CollapseButton from './CollapseButton';
import { StyledAside, StyledMobileMenu, StyledNav, StyledTop } from './styled';
import NavLinkItem from './NavLinkItem';
import useNavHooks from './useNavHooks';
import useDevice from 'hooks/useDevice';
import { Horizontal, StretchSpacer, Vertical } from 'gls';
import IconButton from 'components/Button/IconButton';
import UserInfoDropdown from 'components/Header/components/UserInfoDropdown/UserInfoDropdown';
import MapIcon from './icons/MapIcon';
import { PERMISSIONS } from 'config/permissions';
import ListIcon from './icons/ListIcon';
import { routePath } from 'router/routePaths';
import AssetManagementIcon from './icons/AssetManagementIcon';
import { featurePermissionType, userPermissionType } from 'types/permissions';

export type navItemsType = {
  name: string;
  href: string;
  matchingPaths?: string[];
  hidden?: boolean;
  icon?: any;
  userPermissions: userPermissionType[];
  featurePermissions: featurePermissionType[];
  submenu?: navItemsType[];
};

const Navigation = ({
  navCollapsedDefault,
}: {
  navCollapsedDefault: boolean;
}): React.ReactElement => {
  const [userHasCollapsed, setUserHasCollapsed] = useState(false);
  const [navIsCollapsed, setNavIsCollapsed] = useState(userHasCollapsed);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { isMobileDevice, isTabletDevice } = useDevice();

  const navItems: navItemsType[] = useMemo(() => {
    return [
      {
        name: 'Map View',
        href: '/map',
        icon: MapIcon,
        userPermissions: [PERMISSIONS.USER.READ],
        featurePermissions: [PERMISSIONS.FEATURE.ASSET_TRACKING, PERMISSIONS.FEATURE.USER_TRACKING],
      },
      {
        name: 'Assets',
        href: isTabletDevice ? '/assets' : 'assets',
        icon: ListIcon,
        userPermissions: [PERMISSIONS.USER.READ],
        featurePermissions: [PERMISSIONS.FEATURE.ASSET_TRACKING],
        submenu: isMobileDevice
          ? undefined
          : [
            {
              name: 'Asset List',
              matchingPaths: ['/assets', '/edit-asset'],
              href: '/assets',
              userPermissions: [PERMISSIONS.USER.READ],
              featurePermissions: [PERMISSIONS.FEATURE.USER_TRACKING],
            },
          ],
      },

      {
        name: 'Users',
        href: isMobileDevice ? routePath.recentUsers : 'users',
        icon: ListIcon,
        userPermissions: [PERMISSIONS.USER.READ],
        featurePermissions: [PERMISSIONS.FEATURE.USER_TRACKING],
        submenu: isMobileDevice
          ? undefined
          : [
            {
              name: 'Current Locations',
              href: routePath.recentUsers,
              userPermissions: [PERMISSIONS.USER.READ],
              featurePermissions: [PERMISSIONS.FEATURE.USER_TRACKING],
            }
          ],
      },
      {
        name: 'Management',
        href: isMobileDevice ? routePath.assetManagement : 'management',
        icon: AssetManagementIcon,
        userPermissions: [PERMISSIONS.USER.WRITE],
        featurePermissions: [PERMISSIONS.FEATURE.ASSET_TRACKING],
        submenu: isMobileDevice
          ? undefined
          : [
            {
              name: 'Asset Management',
              href: routePath.assetManagement,
              userPermissions: [PERMISSIONS.USER.WRITE],
              featurePermissions: [PERMISSIONS.FEATURE.ASSET_TRACKING],
            },
            {
              name: 'Beacon Management',
              href: '/beacon-management',
              userPermissions: [PERMISSIONS.USER.WRITE],
              featurePermissions: [PERMISSIONS.FEATURE.USER_TRACKING],
            },
          ],
      },
    ];
  }, [isTabletDevice, isMobileDevice]);

  const { toggleNavOpen, activeHref, setActiveHref } = useNavHooks({
    userHasCollapsed,
    navCollapsedDefault,
    navIsCollapsed,
    setNavIsCollapsed,
    setUserHasCollapsed,
  });

  if (mobileMenuOpen) {
    return (
      <StyledMobileMenu>
        <Vertical className="container" horizontalAlign="left" spacing={10}>
          <IconButton
            className="nav-close-button"
            icon={<icons.CloseIcon width={15} height={15} />}
            onClick={() => setMobileMenuOpen(false)}
          />
          <div className="nav-userinfo">
            <UserInfoDropdown closeMobileMenu={() => setMobileMenuOpen(false)} />
          </div>
          <div className="nav-seperator"></div>
          <StyledNav>
            <ul className="nav-menu-list">
              {navItems.filter(link => !link.hidden).map((link) => {
                return (
                  <NavLinkItem
                    key={link.href}
                    link={link}
                    navIsCollapsed={false}
                    activeHref={activeHref}
                    setActiveHref={setActiveHref}
                    setNavIsCollapsed={setNavIsCollapsed}
                    setUserHasCollapsed={setUserHasCollapsed}
                    closeMobileMenu={() => setMobileMenuOpen(false)}
                  />
                );
              })}
            </ul>
          </StyledNav>
          <StretchSpacer />
          <VersionReadout />
        </Vertical>
      </StyledMobileMenu>
    );
  }

  if (isTabletDevice) {
    return (
      <StyledTop>
        <Horizontal spacing="auto" className="nav-container" horizontalAlign="center">
          <IconButton
            id="mobile-nav-button"
            icon={<icons.MobileMenu width={22} height={22} />}
            onClick={() => setMobileMenuOpen(true)}
          />
          <img src={logo} height="35px" alt="Navenio Logo" />
          <div className="nav-search">&nbsp;</div>
        </Horizontal>
      </StyledTop>
    );
  }

  return (
    <StyledAside className={navIsCollapsed ? '--collapsed' : ''}>
      <div className="nav-container">
        <div className="logo-container">
          <img src={navIsCollapsed ? logoSmall : logo} alt="Navenio Logo" />
        </div>

        <CollapseButton onClick={toggleNavOpen} />

        <StyledNav>
          <ul className="nav-menu-list">
            {navItems.map((link) => {
              return (
                <NavLinkItem
                  key={link.href}
                  link={link}
                  navIsCollapsed={navIsCollapsed}
                  activeHref={activeHref}
                  setActiveHref={setActiveHref}
                  setNavIsCollapsed={setNavIsCollapsed}
                  setUserHasCollapsed={setUserHasCollapsed}
                />
              );
            })}
          </ul>
        </StyledNav>
        <VersionReadout />
      </div>
    </StyledAside>
  );
};
export default Navigation;
