import { icons } from "assets/icons";
import IconButton from "components/Button/IconButton";
import { useRolesListStore, useSystemAdminPermissionStore } from "contexts/RootStore.context";
import { Horizontal } from "gls/lib";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { configRoutePath } from "router/routePaths";
import { colours } from "styling/colours";
import { style } from "typestyle";
import { ErrorResponseDto } from "api/config/ErrorResponseDto";
import { alertErrorMessage } from "utils/alerts";
import { RoleListItem } from "../../RoleConfigDto";

export type TaskTypeListRowProps = {
  role: RoleListItem;
};

export const RowActions = observer(({ role }: TaskTypeListRowProps) => {

  const navigate = useNavigate();
  const { canManageGroupsAndRoles } = useSystemAdminPermissionStore();

  const {
    setDeleteModalOpen,
    setActionLoading,
    loadAssociatedUsers,
    setRoleToDelete

  } = useRolesListStore();

  const handleDeleteRole = async (role: RoleListItem) => {
    setDeleteModalOpen(true);
    setActionLoading(true);
    loadAssociatedUsers(role)
      .then(() => {
        setRoleToDelete(role);
        setActionLoading(false);
      })
      .catch((error: ErrorResponseDto) => {
        setDeleteModalOpen(false);
        setActionLoading(false);
        alertErrorMessage(error.errorMsg)
      });
  }

  return (

    <Horizontal
      spacing={18}
      verticalAlign="center"
      horizontalAlign="right"
      className={paddingStyle}
    >
      <IconButton
        id={canManageGroupsAndRoles ? `button-edit` : `button-view`}
        icon={
          canManageGroupsAndRoles ? (
            <icons.EditIconOutlined
              width={18}
              height={18}
              fill={colours.secondary}
            />
          ) : (
            <icons.OpenEyeIcon
              width={18}
              height={18}
              fill={colours.secondary}
            />
          )
        }
        onClick={() => {
          navigate(`${configRoutePath.editRole}/${role.id}`);
        }}
      />
      {canManageGroupsAndRoles && (
        <IconButton
          id="button-delete"
          icon={<icons.OutlineDeleteIcon />}
          onClick={() => {
            handleDeleteRole(role)
          }}
        />
      )}
    </Horizontal>
  );
});

const paddingStyle = style({
  padding: "20px 0",
});


