import Button from 'components/Button/Button';
import React, { useState } from 'react';
import styled from 'styled-components';
import { DownloadUserLogsModal } from './DownloadUserLogsModal';

const StyledButtonContainer = styled.div`
  margin-right: 10px;
`;
export default function DownloadLogsButton() {
  const [openDownloadModal, setOpenDownloadModal] = useState(false);

  const handleDownloadLog = () => {
    setOpenDownloadModal(true);
  };

  return (
    <StyledButtonContainer>
      <Button
        style={{ width: '200px' }}
        outline
        onClick={handleDownloadLog}
      >
        Download Custom Log
      </Button>
      {openDownloadModal && <DownloadUserLogsModal onClose={() => setOpenDownloadModal(false)} />}
    </StyledButtonContainer>
  );
}
