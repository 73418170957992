import useAuth, { AuthStateTypes } from 'hooks/useAuth.hook';
import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { AuthContextTypes } from 'hooks/useAuth.hook';
import { Cache } from 'aws-amplify/utils';
import LoadingPlaceholderSkeletonPage from 'components/Loading/LoadingPlaceholderPage/LoadingPlaceholderSkeletonPage';
import { signInWithRedirect, getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import config from 'react-global-configuration';

export const AuthenticationContext = createContext({} as AuthContextTypes);
type AuthenticationProviderProps = { children: ReactNode };

const AuthenticationProvider = ({ children }: AuthenticationProviderProps) => {
  const [userAuthChecked, setUserAuthChecked] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const { authState, setAuthState, amplify } = useAuth();

  const value: any = { authState, setAuthState };

  function handleAuthError(e: any) {
    // if current user does not exist or errors, take user directly to signin.
    setIsAuthenticating(false);
    setUserAuthChecked(false);
    console.log('Authentication Error:', e);
    console.log('Authentication: [redirecting to federated signin]');
    signInWithRedirect();
  }

  useEffect(() => {
    // a function to get current authenticated user, and set auth state with cognito auth
    // if this check errors, it means they are not authenticated and we redirect them to the signin page.
    // this will run once, when the application loads after returning from cognito sign in ui.
    // we bypass this if mocking authstate for tests.

    if (!authState.isMock) {
      setIsAuthenticating(true);

      if (config.get().SHOULD_USE_COGNITO) {
        getCurrentUser()
          .then(async (user: any) => {
            const { accessToken } =
              (await (await fetchAuthSession({ forceRefresh: true })).tokens) ?? {};

            const newState = { ...user, isAuthenticated: true, jwtToken: accessToken?.toString() };

            setAuthState(newState);
            Cache.setItem('authState', newState);
            setIsAuthenticating(false);
            setUserAuthChecked(true);
          })
          .catch((e: any) => {
            handleAuthError(e);
          });
      }
      else {
        amplify.currentAuthenticatedUser()
          .then((session: AuthStateTypes) => {
            setAuthState(session);
            Cache.setItem('authState', session);
            setIsAuthenticating(false);
            setUserAuthChecked(true);
          })
          .catch(() => {
            amplify.signInWithRedirect()
            setIsAuthenticating(false);
            setUserAuthChecked(true);
          })
      }

    } else {
      setIsAuthenticating(false);
      setUserAuthChecked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return userAuthChecked && !isAuthenticating ? (
    <AuthenticationContext.Provider value={value}>{children}</AuthenticationContext.Provider>
  ) : (
    <LoadingPlaceholderSkeletonPage />
  );
};

export default AuthenticationProvider;
