import { DataGridColumn } from './models';
import { useSticky } from './plugins/stickyColumn';
import { DataTableStyle } from './style';
import { classes } from 'typestyle';
import { useCommonFeatures } from './plugins/commonFeatures';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import { DataTableCellCheckboxStyle } from './components/DataGridCell';
import { observer } from 'mobx-react';
import {
  Row,
  TableInstance,
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useResizeColumns,
  useSortBy,
  useTable,
} from 'react-table';
import { DataGridPagination } from './components/DataGridPagination';
import { DataGridHeader } from './components/DataGridHeader';
import { DataGridRow } from './components/DataGridRow';
import { useDebounceFilters } from './plugins/useDebounceFilter';
import { useDataTableStore } from 'contexts/RootStore.context';
import useDevice from 'hooks/useDevice';
import { usePageScroll } from 'components/ScrollContainer/usePageScroll';
import { LoadMask } from 'components/LoadMask';
import { ScrollButton } from 'components/ScrollContainer/ScrollButton';

type DataTableProps = {
  ref: string;
  columns: DataGridColumn[];
  data: Record<string, any>[];
  loading?: boolean;
  hideCellValuesTooltip?: boolean;
  height?: number;
  initialState?: Record<string, any>;
  state?: Record<string, any>;
  enablePagination?: boolean;
  previousPageValidation?: boolean;
  nextPageValidation?: boolean;
  getTableInstance?: (instance: TableInstance) => void;
  backComponent?: JSX.Element;
  nextComponent?: JSX.Element;
  hideTopButton?: boolean;
  nonInteractivePageNumbers?: boolean;
  emptyResultText?: string;
  globalFilterFn?: (rows: Row<{}>[], ids: string[], query: any) => Row<{}>[];
}

const DataGrid = observer(forwardRef<any, DataTableProps>((props: DataTableProps, ref) => {

  const {
    reset,
    data,
    gridLoading,
    editingRowIndex,
    isNewRow,
    setColumn,
    setData,
    setGridLoading,
    scrollingButtonShow,
    editingRowValues,
    changeRowValues,
    errors,
    setTotalDisplayedRows,
  } = useDataTableStore();

  useEffect(() => {
    setColumn(props.columns);
    setData(props.data);
    setGridLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    initialState = {},
    state = {},
    enablePagination = false,
    previousPageValidation = true,
    nextPageValidation = true,
    getTableInstance,
    backComponent,
    nextComponent,
    emptyResultText = "No Results",
  } = props;

  const { dimension } = useDevice();
  const boundingElementRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const { handleScroll, showOnScroll } = usePageScroll(
    boundingElementRef,
    bodyRef
  );

  const { columns, hiddenColumns } = useMemo(() => {
    const hiddenColumns: string[] = [];
    const columns = props.columns.map(col => {
      if (col.hidden) {
        hiddenColumns.push(col.accessor);
      }
      const schema = col.editor;
      if (schema) {
        if (!schema["id"]) {
          schema["id"] = col.accessor;
        }
        if (schema.type === "CheckBox") {
          schema.props = { ...schema.props, ...{ containerClassName: DataTableCellCheckboxStyle } }
        }
      }
      return col;
    })
    return { columns, hiddenColumns };
  }, [props.columns]);

  useEffect(() => setColumn(props.columns), [props.columns, setColumn])
  useEffect(() => setData(props.data), [props.data, setData])

  const useInstance = (instance: TableInstance) => {
    if (instance && instance.getTableInstance) {
      instance.getTableInstance(instance);
    }
  };

  const tableInstance: TableInstance<Record<string, any>> =
    useTable({
      columns,
      data,
      getTableInstance,
      initialState: {
        hiddenColumns: hiddenColumns,
        ...initialState
      },
      state: {
        ...state
      },
      globalFilter: props.globalFilterFn,
      autoResetPage: false,
      autoResetGlobalFilter: false,
    },
      (hooks) => hooks.useInstance.push(useInstance),
      useFlexLayout,
      useResizeColumns,
      useCommonFeatures,
      useSticky,
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination,
      useDebounceFilters
    );
  useImperativeHandle(ref, () => tableInstance);

  useEffect(() => {
    const totalDisplayedRows = tableInstance.filteredRows.length;
    setTotalDisplayedRows(totalDisplayedRows);
  }, [tableInstance.filteredRows, setTotalDisplayedRows]);

  useEffect(() => {
    if (editingRowIndex === undefined) {
      return;
    }
    const elements = document.querySelectorAll(`[id*="datatable-editor-${editingRowIndex}"]`);
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].tagName === "INPUT") {
        (elements[i] as HTMLInputElement).focus();
        break;
      }
    }
    if (isNewRow) {
      document.getElementById("datatable")?.scrollTo(0, 0);
    }

  }, [editingRowIndex, isNewRow])

  useEffect(() => {
    return () => {
      reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const rows = (() => {
    if (!gridLoading && !props.loading && tableInstance.rows.length === 0) {
      return (<div className={DataTableStyle.emptyBody}>{emptyResultText}</div>);
    }
    return (
      <div {...tableInstance.getTableBodyProps()} className="body" ref={bodyRef}>
        {(enablePagination ? tableInstance.page : tableInstance.rows).map((row: Row, index) => {
          tableInstance.prepareRow(row);
          if (editingRowIndex === row.index) {
            return (
              <DataGridRow
                row={row}
                key={`datatable-row-${row.index}`}
                hideCellValuesTooltip={props.hideCellValuesTooltip}
                editingMode={true}
                editingRowValues={editingRowValues}
                changeRowValues={changeRowValues}
                errors={errors}
                isNewRow={isNewRow}
              />
            )
          }
          else {
            return (
              <DataGridRow
                row={row}
                key={`datatable-row-${row.index}`}
                hideCellValuesTooltip={props.hideCellValuesTooltip}
                editingMode={false}
              />
            )
          }
        })}
      </div>
    );
  })();

  const maxHeight = (props.height || dimension.height) - 200 - (enablePagination || backComponent ? 100 : 0);

  return (
    <div className={DataTableStyle.container}>
      <LoadMask isShow={gridLoading || props.loading || false} />
      {showOnScroll && !props.hideTopButton && (scrollingButtonShow &&
        <ScrollButton right='30px' bottom={props.enablePagination ? "60px" : "20px"} onClick={handleScroll} />)
      }
      <div {...tableInstance.getTableProps()}
        className={classes(DataTableStyle.table)}
        style={{
          overflowY: "auto",
          maxHeight: maxHeight,
        }}
        ref={boundingElementRef}
        id='datatable'
      >

        <DataGridHeader headerGroups={tableInstance.headerGroups} />
        {rows}

      </div>
      <div className={DataTableStyle.paginationContainer}>
        <div>{backComponent}</div>
        <div>
          {enablePagination &&
            <DataGridPagination
              nonInteractivePageNumbers={props.nonInteractivePageNumbers}
              table={tableInstance}
              previousPageValidation={previousPageValidation}
              nextPageValidation={nextPageValidation}
            />
          }
        </div>
        <div className='next'>{nextComponent}</div>
      </div>
    </div>
  )
}));


export default DataGrid;
