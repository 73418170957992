import { icons } from "assets/icons";
import { observer } from "mobx-react";
import { useUser } from "../hooks/useUser";
import { useUserManagementStore } from "contexts/RootStore.context";
import Button from "components/Button/Button";
import { GenericModal } from "components/Modals/GenericModal";
import { RoundedIcon } from "components/RoundedIcon";
import { colours } from "styling/colours";

const ExportUsers = observer(() => {

    const { showExport, exportHandler } = useUser();
    const { usersLoading, isAnyPendingImportProcess } = useUserManagementStore();

    if (usersLoading) {
        return (<></>)
    }
    return (
        <>
            <Button
                outline
                disabled={isAnyPendingImportProcess}
                onClick={exportHandler}
                id="export-button"
            >
                Export
            </Button>

            <GenericModal
                title={<b>Download in Progress</b>}
                subtitle="Please wait while we download your data, this could take a few mins. You can open a new tab to continue browsing."
                cancelName=""
                hideCloseButton
                icon={
                    <RoundedIcon fillColour={colours.secondaryText}>
                        <icons.DownloadIcon height={32} width={32} fill={colours.secondaryText} />
                    </RoundedIcon>
                }
                isOpen={showExport}
            />
        </>
    )
});

export default ExportUsers;