import { GetLoggedInUserSystemAdminPermissionsRequest } from "api/config/systemAdmin";
import { action, computed, makeObservable, observable } from "mobx";
import { RootStore } from "stores/RootStore";
import { SystemAdminPermission } from "views/config/SystemAdministrators/SystemAdminPermissionsRequestDto";

export class SystemAdminPermissionStore {
  root: RootStore;
  systemAdminPermissions: Partial<Record<SystemAdminPermission, boolean>> = {};
  loading = true;

  constructor(root: RootStore) {
    this.root = root;
    makeObservable(this, {
      systemAdminPermissions: observable,
      loading: observable,

      canManageSystemAdministrators: computed,
      canManageUsers: computed,
      canManageGroupsAndRoles: computed,
      canViewConfig: computed,

      loadPermissions: action.bound,
      setSystemAdminPermissions: action.bound,
    });
    this.loadPermissions();
  }

  get canManageSystemAdministrators() {
    return this.systemAdminPermissions.manage_system_admins || false;
  }

  get canManageUsers() {
    return this.systemAdminPermissions.manage_users || false;
  }

  get canManageGroupsAndRoles() {
    return this.systemAdminPermissions.manage_groups_and_roles || false;
  }

  get canViewConfig() {
    return this.systemAdminPermissions.view_system_configuration || false;
  }

  //#endregion "getters"

  loadPermissions() {
    this.loading = true;
    GetLoggedInUserSystemAdminPermissionsRequest()
      .then((response) => this.setSystemAdminPermissions(response))
      .catch((err) => {
        return undefined;
      })
      .finally(() => this.loading = false);
  }

  //#region "setters"

  setSystemAdminPermissions(systemAdminPermissions: SystemAdminPermission[]) {

    systemAdminPermissions.forEach((permission) => {
      this.systemAdminPermissions[permission] = true
    });

    this.loading = false
  }
  //#endregion "setters"
}
