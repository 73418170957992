import { icons } from "assets/icons";
import { Select } from "components/inputs/Select/Select";
import { Tooltip } from "components/Tooltip";
import { Grid, StretchSpacer } from "gls/lib";
import { observer } from "mobx-react";
import { useState, useEffect } from "react";
import { TableInstance } from "react-table";
import { style } from "typestyle";
import { useDeepCompareEffect } from "use-deep-compare";
import ExportUsers from "./ExportUsers";
import { useDataTableStore, useSystemAdminPermissionStore, useUserManagementStore } from "contexts/RootStore.context";
import { KeyValuePair } from "utils/common";
import { colours } from "styling/colours";
import { GridSearchInput } from "components/SearchInput/GridSearchInput";
import { AddUserButton } from "./AddUserButton";

type FilterUsersProps = {
  table?: TableInstance
}
const FilterUsers = observer(({ table }: FilterUsersProps) => {

  const { stopEditingRow } = useDataTableStore();
  const { users, filters, setFilters, FilterData, isAnyPendingImportProcess } = useUserManagementStore();
  const { canManageUsers } = useSystemAdminPermissionStore();
  const [groups, setGroups] = useState<KeyValuePair[]>([]);

  useEffect(() => {
    let EnableCnt = 0;
    let DisableCnt = 0;
    users.forEach(user => user.enabled ? EnableCnt++ : DisableCnt++);
    const rows = [
      { key: `All (${users.length})`, value: "__all" },
      { key: `Enabled Users (${EnableCnt})`, value: "true" },
      { key: `Disabled Users (${DisableCnt})`, value: "false" }];
    setGroups(rows);
    setFilters({
      "enabled": rows[0].value,
      ...filters
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users])

  useDeepCompareEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      FilterData();
      table?.gotoPage(0);
    }, 100)

    return () => clearTimeout(delayDebounceFn)
  }, [filters])

  return (
    <>
      <Grid className={containerStyle} spacing={10}>
        <GridSearchInput
          value={filters["name"]}
          onChange={(value) => {
            stopEditingRow();
            setFilters({
              ...filters,
              name: value
            })
          }}
          placeHolder="Search for users"
          height={50}
        />
        <Select
          value={filters["enabled"]}
          options={groups}
          onChange={(value) => {
            setFilters({
              ...filters,
              "enabled": value
            })
          }}
        />
        <StretchSpacer />
        {canManageUsers && isAnyPendingImportProcess &&
          <Tooltip content={
            <icons.Loading2Icon height={32} width={32} className={style({ marginTop: 8 })} />
          }>
            {isAnyPendingImportProcess ? "Import in progress. Please try again later." : ""}
          </Tooltip>
        }
        <AddUserButton table={table} />
        <ExportUsers />
      </Grid>
    </>
  )
});

export default FilterUsers;

const containerStyle = style({
  backgroundColor: colours.panelBackground,
  padding: '10px',
  borderRadius: '6px',
})