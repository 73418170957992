
export enum SystemAdminPermission {
  ViewSystemConfiguration = "view_system_configuration",
  ManageUsers = "manage_users",
  ManageGroupsAndRoles = "manage_groups_and_roles",
  ManageSystemAdmins = "manage_system_admins",
}


export class AdminPermissionsDto {
  users: string[] = [];

  permissions: SystemAdminPermission[] = [];
}

export class SystemAdminUserDto {
  user_id: string = "";
  name: string = "";
}