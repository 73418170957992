import { SystemAdminUserDto } from "views/config/SystemAdministrators/SystemAdminUserDto";

export type WorkGroup = {
  id: string;
  name: string;
  type: string;
}

export class WorkgroupDto {
  id: string = "";
  name: string = "";

  administrators: SystemAdminUserDto[] = [];
}

export class WorkgroupRequestDto {
  name: string = "";
  defaultRoles: boolean = false;
}