import { ErrorResponseDto } from "api/config/ErrorResponseDto";
import Button from "components/Button/Button";
import { GroupSection } from "components/GroupSection";
import { Input } from "components/inputs/Input/Input";
import { MultiSelect } from "components/inputs/Select/MultiSelect";
import { Toggle } from "components/inputs/Toggle/Toggle";
import { useSystemAdminPermissionStore, useSystemAdministratorsStore } from "contexts/RootStore.context";
import { Horizontal, Vertical } from "gls/lib";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { formStyle } from "styling/config.style";
import { alertErrorMessage, alertSuccessMessage } from "utils/alerts";

const SystemAdminForm = observer(() => {
  const {
    currentAdmin,
    form,
    loading,
    nonSystemAdmins,
    setEditingMode,
    saveAdmin,
    isAnyChange,
    loadNonAdmins,
  } = useSystemAdministratorsStore();
  const { canManageSystemAdministrators } = useSystemAdminPermissionStore();


  useEffect(() => {
    if (!currentAdmin) {
      loadNonAdmins()
    }
  }, [currentAdmin, loadNonAdmins])

  const addHandler = () => {
    saveAdmin()
      .then(() => {
        const msg = currentAdmin
          ? `Changes saved for ${currentAdmin.name}`
          : `System administrator${form.$.users.value.length > 1 ? 's have' : ' has'} been added`
        alertSuccessMessage(msg)
      })
      .catch((error: ErrorResponseDto) => {
        alertErrorMessage(error.errorMsg)
      })
  }

  return (
    <Vertical>
      <Vertical sizing="stretch" className={formStyle} scroll="vertical" padding={30}>
        {!currentAdmin && (
          <MultiSelect
            id="nonSystemAdmins"
            name="nonSystemAdmins"
            label="Select User(s)"
            required
            value={form.$.users.value}
            options={nonSystemAdmins}
            onChange={form.$.users.onChange}
            width="350px"
            menuPlacement="bottom"
            autoGrow
            isDisabled={!!currentAdmin}
          />
        )}
        {currentAdmin && (
          <Input
            value={currentAdmin.name}
            onChange={() => { }}
            name="system-admin-user"
            disabled
            required
            label="Select User(s)"
          />
        )}
        <GroupSection title="Manage">
          <Toggle
            id="sysAdminReadOnly"
            value={true}
            name="sysAdminReadOnly"
            onChange={() => { }}
            disabled={!canManageSystemAdministrators}
            label="Read Only (All Configuration Pages)"
            labelPlace="right"
          />
          <Toggle
            id="workGroupsAndRoles"
            value={form.$.workgroupsAndRoles.value}
            name="workGroupsAndRoles"
            onChange={form.$.workgroupsAndRoles.onChange}
            disabled={!canManageSystemAdministrators}
            label="Workgroups and Roles"
            labelPlace="right"
          />
          <Toggle
            id="userManagement"
            value={form.$.userManagement.value}
            name="userManagement"
            onChange={form.$.userManagement.onChange}
            disabled={!canManageSystemAdministrators}
            label="User Management"
            labelPlace="right"
          />
          <Toggle
            id="systemAdministrators"
            value={form.$.systemAdministrators.value}
            name="systemAdministrators"
            onChange={() => {
              form.$.systemAdministrators.onChange(
                !form.$.systemAdministrators.value
              );
            }}
            disabled={!canManageSystemAdministrators}
            label="System Administrators"
            labelPlace="right"
          />
        </GroupSection>
      </Vertical >
      <Horizontal horizontalAlign="right" padding={[16, 0, 0, 0]}>
        <Button
          onClick={() => setEditingMode(false)}
          outline
          disabled={loading}
        >
          Cancel
        </Button>
        {canManageSystemAdministrators && (
          <Button
            onClick={addHandler}
            disabled={form.hasError || !isAnyChange}
            isLoading={loading}
            id="save-system-admin-button"
          >
            {currentAdmin ? 'Save' : 'Add'}
          </Button>
        )}
      </Horizontal>
    </Vertical>
  );
});

export default SystemAdminForm;
