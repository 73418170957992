import axios from 'axios';
import config from 'react-global-configuration';

class ExportAssetsApi {
  protected token: string;
  protected config: Headers;
  protected axiosConfig: any;
  protected queryString: URLSearchParams;

  public constructor(token: string) {
    this.config = new Headers();
    this.token = token;
    this.config.set('Content-Type', 'application/json');
    this.config.set('Accept', 'application/json');
    this.config.set('Authorization', `Bearer ${this.token}`);
    this.queryString = new URLSearchParams();

    this.axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${this.token}`,
      },
    };
  }

  public createNewJob = async () => {
    const url = `${config.get().DATA_API_URL}/entity/asset/export/job`;

    return axios.post(url, null, this.axiosConfig);
  };

  public getJobStatus = async (jobID: string) => {
    const url = `${config.get().DATA_API_URL}/entity/asset/export/job/${jobID}/status`;

    return axios.get(url, this.axiosConfig);
  };

  public deleteJob = async (jobID: string) => {
    const url = `${config.get().DATA_API_URL}/entity/asset/export/job/${jobID}`;

    return axios.delete(url, this.axiosConfig);
  };

  public CreateLogExportJob = async (start_date: string, end_date: string): Promise<any> => {
    const queryString = new URLSearchParams();
    queryString.append('start_date', start_date);
    queryString.append('end_date', end_date);

    const url = `${config.get().DATA_API_URL
      }/event/change/asset/export/job?${queryString.toString()}`;

    return axios.post(url, null, this.axiosConfig);
  };

  public GetLogExportJobStatus = async (jobId: string | null): Promise<any> => {
    return axios.get(
      `${config.get().DATA_API_URL}/event/change/asset/export/job/${jobId}/status`,
      this.axiosConfig,
    );
  };

}
export default ExportAssetsApi;
