import { ErrorResponseDto } from "api/config/ErrorResponseDto";
import HeaderDefault from "components/Header/HeaderDefault";
import LoadingScreen from "components/Loading/LoadingScreen";
import { useRoleStore, useSystemAdminPermissionStore } from "contexts/RootStore.context";
import useDevice from "hooks/useDevice";
import { isString } from "lodash";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { configRoutePath } from "router/routePaths";
import { alertErrorMessage, alertSuccessMessage } from "utils/alerts";
import WidthNotSupportedPage from "views/WidthNotSupportedPage/WidthNotSupportedPage";
import { RoleForm } from "./RoleForm";

const Role = observer(() => {

  const params = useParams<{ roleId: string | undefined }>();
  const editMode = isString(params.roleId) ? false : true;

  const {
    dataLoading,
    LoadData,
    roleForm,
    setCurrentRoleId,
    reset,
    SaveRole,
  } = useRoleStore();

  const { canManageGroupsAndRoles: manageRoleAccess } = useSystemAdminPermissionStore();
  const navigate = useNavigate();
  const { isMobileDevice } = useDevice();
  const [canManageRoles, setCanManageRoles] = useState(manageRoleAccess);

  useEffect(() => {
    const abortController = new AbortController();
    setCurrentRoleId(params.roleId);
    LoadData(params.roleId)
      .finally(() => {
        if (abortController.signal.aborted) {
          reset();
        }
      });

    return () => {
      abortController.abort();
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode, params.roleId]);

  const saveHandler = async () => {
    SaveRole(params.roleId)
      .then(() => {
        navigate(configRoutePath.listRoles);
        alertSuccessMessage(!params.roleId
          ? `${roleForm.$.name.value} Role has been created`
          : `Changes saved for the ${roleForm.$.name.value} role`)
      })
      .catch((error: ErrorResponseDto) => {
        alertErrorMessage(error.errorMsg)
      })
  }

  // useEffect(() => {
  //   if (params.roleId && accessedWorkgroups.find(r => r.value === roleForm.$.workgroupId.value) === undefined) {
  //     setCanManageRoles(false);
  //   }
  //   else
  //     setCanManageRoles(manageRoleAccess);
  // }, [accessedWorkgroups, manageRoleAccess, params.roleId, roleForm.$.workgroupId.value])

  if (isMobileDevice) {
    return <WidthNotSupportedPage />;
  }

  return (
    <>
      <HeaderDefault />
      {dataLoading ? <LoadingScreen /> : (
        <RoleForm
          saveHandler={saveHandler}
          canManageRoles={canManageRoles}
          roleId={params.roleId}
        />
      )}
    </>
  );
});

export default Role;