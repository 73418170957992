import { icons } from "assets/icons";
import { BaseInput } from "components/inputs/Input/BaseInput";
import { Content } from "gls/lib";
import { isNumber } from "lodash";
import React, { forwardRef } from "react";
import { colours } from "styling/colours";
import { style } from "typestyle";

export interface SearchInputProps {
  id?: string;
  width?: number | string;
  height?: number;
  value: string;
  onChange: (value: string) => void;
  placeHolder?: string;
  onSubmit?: (evt: React.FormEvent) => Promise<void>;
  onBlur?: () => void;
  onClear?: () => void;
  onFocus?: () => void;
}

export const GridSearchInput = forwardRef<HTMLInputElement, SearchInputProps>((props, inputRef) => {
  const { value, onChange, onSubmit } = props;
  const compWidth = isNumber(props.width) ? `${props.width || 290}px` : props.width;
  const compWidthImportant = `${compWidth} !important`;
  const height = props.height || 40;

  const handleClear = () => {
    onChange("");
  };

  const renderBaseInput = () => (
    <BaseInput
      ref={inputRef}
      id={props.id || "base-search-input"}
      className={baseStyle(compWidthImportant, height)}
      type="text"
      placeholder={props.placeHolder}
      value={value}
      onChange={onChange}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
    />
  );

  const renderForm = () =>
    onSubmit ? (
      <form onSubmit={onSubmit} className={style({ width: compWidth })} autoComplete="off">
        {renderBaseInput()}
      </form>
    ) : (
      renderBaseInput()
    );

  return (
    <Content
      className={style({
        width: compWidth,
        position: "relative",
        $nest: {
          "svg path": {
            fill: colours.secondaryText,
          },
        },
      })}
    >
      <icons.SearchIcon
        className={style({
          position: "absolute",
          top: height / 2 - 8,
          left: 16,
        })}
      />
      {renderForm()}
      {value?.length ? (
        <Content onClick={handleClear} className={contentStyle(height)}>
          <icons.CrossIcon width={16} height={16} className={iconStyle} />
        </Content>
      ) : null}
    </Content>
  );
});

const baseStyle = (compWidthImportant: string, height: number) =>
  style({
    width: compWidthImportant,
    height: `${height}px !important`,
    borderRadius: "6px !important",
    borderColor: colours.secondaryText,
    $nest: {
      'input[type="text"]': {
        padding: "0 40px !important",
        backgroundColor: colours.white,
        $nest: {
          "&:focus": {
            background: "white",
            borderColor: `${colours.secondary} !important`,
          },
        },
      },
      "::placeholder": {
        color: colours.secondaryText,
      },
    },
  });

const contentStyle = (height: number) => style({
  cursor: "pointer",
  position: "absolute",
  height: "16px",
  width: "16px",
  borderRadius: "16px",
  background: colours.tertiaryText,
  top: height / 2 - 8,
  right: 12,
  $nest: {
    "> svg .st1": {
      fill: "white",
    },
    "> svg .st0": {
      fill: colours.tertiaryText,
    },
  },
});

const iconStyle = style({
  position: "absolute",
  top: 0,
  left: 0,
  $nest: {
    "svg st0": {
      fill: `${colours.tertiaryText} !important`,
    },
  },
});
