import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import routes from './routes';
import PreRoute from './PreRoute';
import Navigation from 'components/Navigation/Navigation';
import AuthenticationProvider from 'contexts/authentication.context';
import RenderRouteComponent from './RenderRouteComponent';
import PublicConfigWrapper from 'wrappers/PublicConfigWrapper';
import ErrorBoundary from 'wrappers/ErrorBoundary';
import { ErrorBoundary as SystemErrorBoundary } from 'react-error-boundary';
import SystemErrorPage from 'views/SystemErrorPage/SystemErrorPage';
import { RootStoreProvider } from 'contexts/RootStore.context';
import ConfigNavigation from 'components/Navigation/ConfigNavigation';
import { Vertical } from 'gls';
import { appStyle } from 'styling/App.style';

export default function AppRouter() {
  return (
    <BrowserRouter>
      <SystemErrorBoundary fallback={<SystemErrorPage />}>
        <PublicConfigWrapper>
          <AuthenticationProvider>
            <RootStoreProvider>
              <Routes>
                {routes.map((route) => {
                  return route.isPrivate ? (
                    <Route
                      key={route.url}
                      path={route.url}
                      element={
                        <PreRoute route={route}>
                          <PrivateRoute route={route}>
                            <Vertical className={appStyle} height="100%" spacing={0}>
                              <ErrorBoundary>
                                {route.navigation
                                  ? route.isConfig
                                    ? <ConfigNavigation navCollapsedDefault={route.navCollapsedDefault} />
                                    : <Navigation navCollapsedDefault={route.navCollapsedDefault} />
                                  : <></>
                                }
                                {route.redirectTo ? (
                                  <Navigate to={route.redirectTo} />
                                ) : (
                                  <RenderRouteComponent isConfig={route.isConfig} component={route.component} />
                                )}
                              </ErrorBoundary>
                            </Vertical>
                          </PrivateRoute>
                        </PreRoute>
                      }
                    />
                  ) : (
                    <Route
                      key={route.url}
                      path={route.url}
                      element={
                        <PreRoute route={route}>{React.createElement(route.component)}</PreRoute>
                      }
                    />
                  );
                })}
              </Routes>
            </RootStoreProvider>
          </AuthenticationProvider>
        </PublicConfigWrapper>
      </SystemErrorBoundary>
    </BrowserRouter>
  );
}
