import HeaderDefault from 'components/Header/HeaderDefault';
import React from 'react';

export default function SupportPage() {
  return (
    <>
      <HeaderDefault />
      <main className="u-flex-center" style={{ maxHeight: '550px' }}>
        <div className="container">
          <span>
            Please contact us here&nbsp;
            <a
              href='https://navenio.atlassian.net/servicedesk/customer/portal/2'
              target='_blank'
              rel="noreferrer"
            >
              Customer Portal
            </a>
            &nbsp;to report a problem or to give feedback.
          </span>
        </div>
      </main>
    </>
  );
}
