import React from 'react';
import styled from 'styled-components';
import { colours } from 'styling/colours';
import LoadingCircle from 'components/Loading/LoadingCircle';

type ButtonProps = {
  title?: string;
  children: React.ReactNode;
  className?: string;
  buttonType?: string;
  styling?: 'secondary' | 'cancel' | 'neutral';
  height?: 'normal' | 'short';
  size?: 'small' | 'normal' | 'large' | 'flexible';
  outline?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  disabledAction?: boolean;
  secondary?: boolean;
  style?: React.CSSProperties;
  type?: 'reset' | 'submit' | undefined;
  id?: string;
  isLoading?: boolean;
  tabIndex?: number;
  ref?: any;
};
export interface IButtonProps {
  title?: string;
  children: React.ReactNode;
  className?: string;
  buttonType?: string;
  styling?: 'secondary' | 'cancel' | 'neutral';
  height?: 'normal' | 'short';
  outline?: boolean;
  size?: 'small' | 'normal' | 'large' | 'flexible';
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  disabledAction?: boolean;
  secondary?: boolean;
  style?: React.CSSProperties;
  type?: 'reset' | 'submit' | undefined;
  id?: string;
  isLoading?: boolean;
  ref?: any;
}

const buttonColours: any = {
  outline: colours.white,
  primary: colours.secondary,
  cancel: colours.highlight,
  neutral: colours.secondaryText,
};

const generateBorderColours = (type: string | undefined) => {
  return buttonColours[type || 'primary'];
};
const generateBackgroundColours = (type: string | undefined, outline: boolean | undefined) => {
  if (outline) {
    return buttonColours.outline;
  } else {
    if (type === undefined) {
      return buttonColours.primary;
    } else {
      return buttonColours[type];
    }
  }
};
const generateTextColours = (type: string | undefined, outline: boolean | undefined) => {
  if (outline) {
    return buttonColours[type || 'primary'];
  } else {
    return buttonColours['outline'];
  }
};

const generateSize = (size: 'small' | 'normal' | 'large' | 'flexible' | undefined) => {
  if (size === 'flexible') {
    return 'max-content';
  }
  if (size === 'large') {
    return '200px';
  }
  return '130px';
};

const StyledButton = styled.button.withConfig({
  shouldForwardProp: (prop) => !['outline', 'isLoading'].includes(prop),
}) <ButtonProps>`
  width: ${({ size }) => generateSize(size)};
  height: 50px;
  color: ${({ styling, outline }) => generateTextColours(styling, outline)};
  border-style: solid;
  border-width: 1px;
  border-color: ${({ styling }) => generateBorderColours(styling)};
  border-radius: 5px;
  font-family: MuseoSans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  background-color: ${({ styling, outline }) => generateBackgroundColours(styling, outline)};
  padding: 0 12px;
  cursor: pointer;
  &.biggerButton {
    width: 170px;
  height: 50px;
  }
  &:disabled {
    background-color: ${colours.tertiaryText};
  color: ${colours.white};
  border-color: ${colours.tertiaryText};
  &:hover {
    cursor: not-allowed;
    }
  }
  :hover {
    cursor: pointer;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  }
  `;

const Button = React.forwardRef((props: ButtonProps, ref: any) => (
  <StyledButton {...props} ref={ref} disabled={props.disabled || props.isLoading}>
    {props.isLoading ? <LoadingCircle size={25} /> : props.children}
  </StyledButton>
));
Button.displayName = 'Button';

export default Button;
