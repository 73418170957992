import { icons } from 'assets/icons';
import React, { useContext, useState } from 'react';
import PageTitle from 'components/Header/components/PageTitle';
import AssetSearchField from './AssetSearch/AssetSearchField';
import usePageInfo from 'hooks/pageInfo.hook';
import useDevice from 'hooks/useDevice';

import { Horizontal, StretchSpacer } from 'gls';
import IconButton from 'components/Button/IconButton';
import { AssetListPageContext } from 'contexts/assetListPage.context';
import { isEmpty } from 'lodash';
import DownloadAssetsButton from 'views/AssetListPage/components/DownloadAssetsButton';
import ComponentUserPermissionRenderWrapper from 'wrappers/ComponentConditionWrappers/ComponentUserPermissionRenderWrapper';
import { PERMISSIONS } from 'config/permissions';
import { HeaderStyled, SearchStyleDiv, StyledSubHeading } from './style';
import { style } from 'typestyle';
import { colours } from 'styling/colours';

const HeaderAssetSearch = () => {
  const { headerAssetCount, contextPageTitle } = usePageInfo();
  const { isMobileDevice } = useDevice();
  const paddedObjectCount = headerAssetCount.toString().padStart(2, '0');
  const { searchTerm } = useContext(AssetListPageContext);
  const [showSearch, setShowSearch] = useState(() => !isEmpty(searchTerm));

  if (isMobileDevice) {
    return (
      <div className="container">
        {showSearch ? (
          <SearchStyleDiv>
            <div className="search-container">
              <AssetSearchField />
              {isEmpty(searchTerm) && (
                <IconButton
                  className="close-search"
                  icon={<icons.CloseIcon width="15px" height="15px" />}
                  onClick={() => setShowSearch(false)}
                />
              )}
            </div>
            <StyledSubHeading data-test-id="assetCount">
              # {paddedObjectCount} Total Asset{headerAssetCount === 1 ? '' : 's'}
            </StyledSubHeading>
          </SearchStyleDiv>
        ) : (
          <HeaderStyled className="page-header">
            <div className="header-item title">
              <Horizontal width="100%">
                <PageTitle pageTitle={contextPageTitle} />
                <StretchSpacer />
                <IconButton icon={<icons.SearchIcon />} onClick={() => setShowSearch(true)} />
              </Horizontal>
              <StyledSubHeading data-test-id="assetCount">
                # {paddedObjectCount} Total Asset{headerAssetCount === 1 ? '' : 's'}
              </StyledSubHeading>
            </div>
          </HeaderStyled>
        )}
      </div>
    );
  }

  return (
    <div className="container">
      <Horizontal className={headerStyle} spacing="0">
        <AssetSearchField />
        <StretchSpacer />
        <ComponentUserPermissionRenderWrapper userPermissions={PERMISSIONS.USER.WRITE}>
          <DownloadAssetsButton />
        </ComponentUserPermissionRenderWrapper>
      </Horizontal>
    </div >
  );
};
export default HeaderAssetSearch;

const headerStyle = style({
  width: '100%',
  padding: "10px",
  marginBottom: "10px",
  backgroundColor: colours.panelBackground,
  borderRadius: "6px",
})