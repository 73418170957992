import { observer } from "mobx-react";
import { Horizontal, StretchSpacer, Vertical } from 'gls/lib';
import { style } from "typestyle";
import { RowActions } from "./components/RowActions";
import { WorkgroupSelect } from "./components/WorkgroupSelect";
import { useEffect, useMemo } from "react";
import { icons } from "assets/icons";
import { useParams } from "react-router-dom";
import { useRolesListStore } from "contexts/RootStore.context";
import LoadingScreen from "components/Loading/LoadingScreen";
import { GenericModal } from "components/Modals/GenericModal";
import { RoleListItem } from "../RoleConfigDto";
import { getConfigApiUrl } from "utils/utils";
import { alertErrorMessage, alertSuccessMessage } from "utils/alerts";
import { colours } from "styling/colours";
import Button from "components/Button/Button";
import HeaderDefault from "components/Header/HeaderDefault";
import { ErrorResponseDto } from "api/config/ErrorResponseDto";
import WidthNotSupportedPage from "views/WidthNotSupportedPage/WidthNotSupportedPage";
import useDevice from "hooks/useDevice";
import { blobDownload } from "api/config/base";
import { StyledDataTableWrapper } from "components/DataTable/style";
import DataTable, { TableColumn } from "react-data-table-component";
import DefaultCell from "components/DataTable/cells/DefaultCell";
import { datatableStyle } from "components/DataTable/DataTableStyling.config";
import { Tooltip } from "components/Tooltip";
import NoDataComponent from "components/DataTable/NoDataComponent";

const RolesList = observer(() => {

  const {
    listLoading,
    actionLoading,
    LoadData,
    dataLoadingError,
    roles,
    associatedUsersNo,
    workgroups,
    selectedWorkgroup,
    changeWorkgroup,
    deleteRole,
    roleToDelete,
    deleteModalOpen,
    setDeleteModalOpen,
    setRoleToDelete,
    reset,
  } = useRolesListStore();

  const { isMobileDevice } = useDevice();
  const params = useParams<{ workgroupId: string | undefined }>();
  const handleRolesExport = () => blobDownload(`${getConfigApiUrl()}/v1/roles/export`)

  useEffect(() => {
    const abortController = new AbortController();
    LoadData(params.workgroupId)
      .finally(() => {
        if (abortController.signal.aborted) {
          reset();
        }
      });

    return () => {
      abortController.abort();
      reset()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.workgroupId]);




  const onDeleteRole = async () => {
    if (roleToDelete) {
      deleteRole(roleToDelete)
        .then(() => {
          alertSuccessMessage(`${roleToDelete.name} role has been deleted`)
        })
        .catch((error: ErrorResponseDto) => {
          alertErrorMessage(error.errorMsg)
        })
        .finally(() => {
          setDeleteModalOpen(false);
          setRoleToDelete(null);
        })
    }
  }

  const columns = useMemo(() => {
    return [
      {
        name: 'Role',
        selector: (row: RoleListItem) => <DefaultCell value={row.name} />,
        minWidth: '240px',
      },
      {
        right: true,
        sortable: false,
        cell: (row: RoleListItem) => <RowActions role={row} />,
        width: '200px',
      }, {
        name: '',
        selector: (row: RoleListItem) =>
        (<Tooltip infoIconContent>
          {row.id}
        </Tooltip>
        ),
        width: '80px',

      }
    ] as TableColumn<RoleListItem>[];
  }, [])

  if (isMobileDevice) {
    return <WidthNotSupportedPage />;
  }

  return (
    <>
      <HeaderDefault />
      {listLoading ? <LoadingScreen /> : (
        <Vertical spacing={0}>
          <Horizontal>
            <WorkgroupSelect
              roles={roles}
              workgroups={workgroups}
              listLoading={listLoading}
              dataLoadingError={dataLoadingError}
              selectedWorkgroup={selectedWorkgroup}
              changeWorkgroup={changeWorkgroup}
            />
            <StretchSpacer />
            {roles &&
              <Button size="large" onClick={handleRolesExport} outline>Export All Roles</Button>
            }
          </Horizontal>
          <StyledDataTableWrapper data-test-id="table">
            <DataTable
              columns={columns}
              data={roles[selectedWorkgroup]}
              defaultSortFieldId={4}
              defaultSortAsc={false}
              customStyles={datatableStyle}
              noDataComponent={
                <NoDataComponent text="There is no role in the selected workgroup"></NoDataComponent>
              }
            />
          </StyledDataTableWrapper>
        </Vertical>
      )}
      <GenericModal
        title="Delete"
        subtitle={
          <>
            <span>
              Are you sure you want to permanently delete the <strong>{roleToDelete?.name}</strong> role?
            </span>
            <br /><br />
            {associatedUsersNo > 0 && (
              <Horizontal verticalAlign="top" spacing={6}>
                <icons.InfoIcon fill={colours.secondaryText} width={32} />
                {actionLoading
                  ? <span>Loading...</span>
                  :
                  <span>
                    Please note there are {associatedUsersNo} users associated to this role. <br />
                    This association will be removed if you delete the role.
                  </span>
                }
              </Horizontal>
            )}
          </>
        }
        actionName="Delete"
        actionEnabled={actionLoading}
        actionStyle="cancel"
        icon={<icons.OutlineDeleteIcon className={ModalIconStyle} height={40} width={40} />}
        isOpen={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onAction={onDeleteRole}
        loading={actionLoading}
      />
    </>
  );

});

export default RolesList;

const ModalIconStyle = style({
  border: "1px solid red",
  borderRadius: "50%",
  padding: "7px",
});
