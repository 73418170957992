import { genericDelete, genericGet, genericPost, genericPut } from "api/config/base";
import { getConfigApiUrl } from "utils/utils";
import { Role, RoleListItem } from "views/config/roles/RoleConfigDto";

export function getRoles(): Promise<RoleListItem[]> {

  return genericGet(`${getConfigApiUrl()}/v1/roles`)
}

export function DeleteRole(roleId: string): Promise<any> {
  return genericDelete(`${getConfigApiUrl()}/v1/roles/${roleId}`);
}

export function UsersAffectedByRole(roleId: string,): Promise<number> {
  return genericGet(
    `${getConfigApiUrl()}/role/${roleId}/affected_users`
  );
}

export function GetRole(roleId: string): Promise<RoleListItem> {
  return genericGet(
    `${getConfigApiUrl()}/v1/roles/${roleId}`
  )
}

export function CreateRole(roleDto: Role): Promise<any> {
  return genericPost(
    `${getConfigApiUrl()}/v1/roles`,
    { role: roleDto }
  )
}

export function UpdateRole(roleId: string, roleDto: Role): Promise<any> {
  return genericPut(
    `${getConfigApiUrl()}/v1/roles/${roleId}`,
    { role: roleDto }
  )
}