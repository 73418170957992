import { debounce } from 'lodash';
import React from 'react'
import {
  actions, ActionType, ColumnInstance, functionalUpdate, Hooks, Row,
  TableInstance, TableState, useGetLatest, useMountedLayoutEffect
} from 'react-table'
import * as filterTypes from './filterTypes'

export function getFirstDefined(...args: (boolean | undefined)[]): boolean {
  for (let i = 0; i < args.length; i += 1) {
    if (args[i] !== undefined) {
      return !!args[i];
    }
  }
  return false;
}
export function isFunction(a: any) {
  if (typeof a === 'function') {
    return a
  }
}
export function getFilterMethod(
  filter: any,
  userFilterTypes: { [x: string]: any },
  filterTypes: { [x: string]: any; text: any }) {
  return (
    isFunction(filter) ||
    userFilterTypes[filter] ||
    filterTypes[filter] ||
    filterTypes.text
  )
}
export function shouldAutoRemoveFilter(
  autoRemove: (arg0: any, arg1: any) => any,
  value: Partial<TableState<{}>>,
  column: ColumnInstance<{}> | undefined) {
  return autoRemove ? autoRemove(value, column) : typeof value === 'undefined'
}

export const useDebounceFilters = (hooks: Hooks) => {
  hooks.stateReducers.push(reducer)
  hooks.useInstance.push(useInstance)
}

useDebounceFilters.pluginName = 'useDebounceFilters'

function reducer(state: TableState, action: ActionType, previousState?: TableState, instance?: TableInstance) {

  if (!instance) {
    return { ...state };
  }

  if (action.type === actions.init) {
    return {
      ...state,
      filters: [],
    }
  }

  if (action.type === actions.resetFilters) {
    return {
      ...state,
      filters: instance.initialState.filters || [],
    }
  }

  if (action.type === actions.setFilter) {
    const { columnId, filterValue } = action
    const { allColumns, filterTypes: userFilterTypes } = instance

    const column = allColumns.find(d => d.id === columnId)

    if (!column) {
      throw new Error(
        `React-Table: Could not find a column with id: ${columnId}`
      )
    }

    const filterMethod = getFilterMethod(
      column.filter,
      userFilterTypes || {},
      filterTypes
    )

    const previousfilter = state.filters.find(d => d.id === columnId)

    const newFilter = functionalUpdate(
      filterValue,
      previousfilter && previousfilter.value
    )

    //
    if (shouldAutoRemoveFilter(filterMethod.autoRemove, newFilter, column)) {
      return {
        ...state,
        filters: state.filters.filter(d => d.id !== columnId),
      }
    }

    if (previousfilter) {
      return {
        ...state,
        filters: state.filters.map(d => {
          if (d.id === columnId) {
            return { id: columnId, value: newFilter }
          }
          return d
        }),
      }
    }

    return {
      ...state,
      filters: [...state.filters, { id: columnId, value: newFilter }],
    }
  }

  if (action.type === actions.setAllFilters) {
    const { allColumns, filterTypes: userFilterTypes } = instance

    return {
      ...state,
      // Filter out undefined values
      filters: state.filters.filter((filter: any) => {
        const column = allColumns.find(d => d.id === filter.id)
        const filterMethod = getFilterMethod(
          column?.filter,
          userFilterTypes || {},
          filterTypes
        )

        if (
          shouldAutoRemoveFilter(filterMethod.autoRemove, filter.value, column)
        ) {
          return false
        }
        return true
      }),
    }
  }
}

function useInstance(instance: TableInstance) {
  const {
    data,
    rows,
    flatRows,
    rowsById,
    allColumns,
    filterTypes: userFilterTypes,
    manualFilters,
    defaultCanFilter = false,
    disableFilters,
    state: { filters },
    dispatch,
    autoResetFilters = true,
  } = instance

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setDebounceFilter = React.useCallback(debounce(
    (columnId, filterValue) => {
      dispatch({ type: actions.setFilter, columnId, filterValue })
    }, 500),
    [dispatch]
  )

  const setDebounceAllFilters = React.useCallback(
    (filters: any) => {
      dispatch({
        type: actions.setAllFilters,
        filters,
      })
    },
    [dispatch]
  )

  allColumns.forEach(column => {
    const {
      id,
      defaultCanFilter: columnDefaultCanFilter,
      disableFilters: columnDisableFilters,
    } = column

    // Determine if a column is filterable
    column.canFilter = id
      ? getFirstDefined(
        columnDisableFilters === true ? false : undefined,
        disableFilters === true ? false : undefined,
        true
      )
      : getFirstDefined(columnDefaultCanFilter, defaultCanFilter, false)

    // Provide the column a way of updating the filter value
    column.setFilter = val => setDebounceFilter(column.id, val)

    // Provide the current filter value to the column for
    // convenience
    const found = filters.find(d => d.id === id)
    column.filterValue = found && found.value
  })

  const [
    filteredRows,
  ] = React.useMemo(() => {
    if (manualFilters || !filters.length) {
      return [rows, flatRows, rowsById]
    }


    // Filters top level and nested rows
    const filterRows = (rows: Row<{}>[], depth = 0) => {
      let filteredRows = rows

      filteredRows = filters.reduce(
        (filteredSoFar, { id: columnId, value: filterValue }) => {
          // Find the filters column
          const column = allColumns.find(d => d.id === columnId)

          if (!column) {
            return filteredSoFar
          }

          if (depth === 0) {
            column.preFilteredRows = filteredSoFar
          }

          const filterMethod = getFilterMethod(
            column.filter,
            userFilterTypes || {},
            filterTypes
          )

          if (!filterMethod) {
            console.warn(
              `Could not find a valid 'column.filter' for column with the ID: ${column.id}.`
            )
            return filteredSoFar
          }

          // Pass the rows, id, filterValue and column to the filterMethod
          // to get the filtered rows back
          column.filteredRows = filterMethod(
            filteredSoFar,
            [columnId],
            filterValue
          )

          return column.filteredRows
        },
        rows
      )

      // Apply the filter to any subRows
      // We technically could do this recursively in the above loop,
      // but that would severely hinder the API for the user, since they
      // would be required to do that recursion in some scenarios
      filteredRows.forEach(row => {

        if (!row.subRows) {
          return
        }

        row.subRows =
          row.subRows && row.subRows.length > 0
            ? filterRows(row.subRows, depth + 1)
            : row.subRows
      })

      return filteredRows
    }

    return [filterRows(rows)]
  }, [
    manualFilters,
    filters,
    rows,
    flatRows,
    rowsById,
    allColumns,
    userFilterTypes,
  ])

  React.useMemo(() => {
    // Now that each filtered column has it's partially filtered rows,
    // lets assign the final filtered rows to all of the other columns
    const nonFilteredColumns = allColumns.filter(
      column => !filters.find(d => d.id === column.id)
    )

    // This essentially enables faceted filter options to be built easily
    // using every column's preFilteredRows value
    nonFilteredColumns.forEach(column => {
      column.preFilteredRows = filteredRows
      column.filteredRows = filteredRows
    })
  }, [filteredRows, filters, allColumns])

  const getAutoResetFilters = useGetLatest(autoResetFilters)

  useMountedLayoutEffect(() => {
    if (getAutoResetFilters()) {
      dispatch({ type: actions.resetFilters })
    }
  }, [dispatch, manualFilters ? null : data])

  Object.assign(instance, {
    setDebounceFilter,
    setDebounceAllFilters,
  })
}
