import { DataGridColumn } from "../models";

function getHeaderProps(column: DataGridColumn, instance: any) {
  let style: Record<string, any> = {};
  const dataAttrs: Record<string, any> = {};

  style['justifyContent'] = !column.headerAlign ? "left" : column.headerAlign;
  style = { ...style , ...column.style};
  if(column.colWidth){
    style["width"] = column.colWidth;
    style["minWidth"] = column.colWidth;
    style["maxWidth"] = column.colWidth;
  }
  if(column.colMinWidth){
    style["minWidth"] = column.colMinWidth;
  }
  if (column.required) {
    dataAttrs['required'] = true;
  }

  return { style, ...dataAttrs };
}
function getDataProps(column: DataGridColumn, instance: any) {
  let style: Record<string, any> = {};

  style['textAlign'] = !column.dataAlign ? "left" : column.dataAlign;
  if(column.colWidth){
    style["width"] = column.colWidth;
    style["minWidth"] = column.colWidth;
    style["maxWidth"] = column.colWidth;
  }
  if(column.colMinWidth){
    style["minWidth"] = column.colMinWidth;
  }
  style = { ...style , ...column.style};

  return { style };
}

export const useCommonFeatures = (hooks: any) => {
  hooks.getHeaderProps.push((props: any, { instance, column }: any) => {
    const nextProps = getHeaderProps(column, instance);
    return [props, nextProps];
  });

  hooks.getCellProps.push((props: any, { instance, cell }: any) => {
    const nextProps = getDataProps(cell.column, instance);
    return [props, nextProps];
  });
};

useCommonFeatures.pluginName = 'useCommonFeatures';
