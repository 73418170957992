import { getRoles } from "api/config/roles";
import { AddUser, ResetUserPassword, UpdateUser, getUsers } from "api/config/users";
import { RoleListItem } from "views/config/roles/RoleConfigDto";
import { UserDto } from "views/config/userManagement/UserDto";
import { WorkGroup } from "views/config/workgroups/WorkgroupDto";
import { keys } from "lodash";
import { action, makeObservable, observable } from "mobx";
import { RootStore } from "stores/RootStore";
import { getWorkgroups } from "api/config/workgroup";

export class UsersStore {

  root: RootStore;
  version: string = "";
  dataLoading = true;
  usersLoading = true;
  dataLoadingError: boolean = false;
  workgroups: WorkGroup[] = [];
  workgroupToRoles: Record<string, RoleListItem[]> = {};
  roleIdToRole: Record<string, RoleListItem> = {};
  users: UserDto[] = [];
  gridData: UserDto[] = [];
  filters: Record<string, any> = {};
  showChangesModal: boolean = false;
  applySelfAssignedRoles: boolean = false;
  isAnyPendingImportProcess: boolean = false;
  isAnyFailedImportProcess: boolean = false;
  maxImportUsersCount: number = 0;

  constructor(root: RootStore) {
    this.root = root;

    makeObservable(this, {
      dataLoading: observable,
      usersLoading: observable,
      dataLoadingError: observable,
      workgroups: observable,
      workgroupToRoles: observable,
      users: observable,
      gridData: observable,
      filters: observable,
      showChangesModal: observable,
      applySelfAssignedRoles: observable,
      isAnyPendingImportProcess: observable,
      isAnyFailedImportProcess: observable,
      maxImportUsersCount: observable,

      LoadData: action.bound,
      CreateUser: action.bound,
      EditUser: action.bound,
      reset: action.bound,
      LoadUsers: action.bound,
      setFilters: action.bound,
      FilterData: action.bound,
      setUsersLoading: action.bound,
      setShowChangesModal: action.bound,
      setApplySelfAssignedRoles: action.bound,
    });

  }

  reset() {
    this.usersLoading = true;
    this.dataLoading = true;
    this.dataLoadingError = false;
    this.workgroups = [];
    this.workgroupToRoles = {};
    this.users = [];
    this.gridData = [];
    this.filters = {};

    this.showChangesModal = false;
    this.applySelfAssignedRoles = false;
    this.isAnyPendingImportProcess = false;
    this.isAnyFailedImportProcess = false;
  }

  private fillWorkgroupToRoles(workgroups: WorkGroup[], roles: RoleListItem[]) {
    workgroups.forEach(r => this.workgroupToRoles[r.id] = [])
    roles.forEach(role => {
      this.workgroupToRoles[role.workgroup.id].push(role)
    })
  }

  setUsersLoading(value: boolean) {
    this.usersLoading = value;
  }

  setShowChangesModal(value: boolean) {
    this.showChangesModal = value;
  }

  setApplySelfAssignedRoles(value: boolean) {
    this.applySelfAssignedRoles = value;
  }

  private async fillUsers(list: UserDto[]) {
    this.users = list;
    // .......... add workgroups columns ................  
    this.users.forEach(user => {
      this.workgroups.forEach(w => user[w.id] = [])
      if (!user.roles)
        return;
      user.roles.forEach(roleId => {
        user[this.roleIdToRole[roleId].workgroup.id].push(roleId)
      })
    })
    //...................................................
    this.users.sort((a, b) => a.name.toLowerCase().trim() > b.name.toLowerCase().trim() ? 1 : -1);
    this.gridData = [...this.users];
    this.FilterData();
  }

  LoadUsers() {
    return getUsers()
      .then(res => {
        this.fillUsers(res.users)
        this.isAnyPendingImportProcess = res.is_import_in_progress;
        this.isAnyFailedImportProcess = res.is_failed_import;
        this.maxImportUsersCount = res.max_import_users_count;
      })
      .catch((error) => {
        this.dataLoadingError = true;
      })
  }

  LoadData(roleId: string | null = null) {
    this.dataLoading = true;
    return Promise.all([
      getWorkgroups(),
      getRoles(),
    ])
      .then(res => {
        this.workgroups = res[0];
        this.fillWorkgroupToRoles(res[0], res[1]);
        this.roleIdToRole = {}
        res[1].forEach(role => this.roleIdToRole[role.id] = role)
      })
      .catch(() => {
        this.dataLoadingError = true;
      })
      .finally(() => {
        this.dataLoading = false;
      })
  }

  prepareUserData(values: any) {
    // ........... fill roles .................
    values.roles = [];
    this.workgroups.forEach(w => {
      if (values[w.id] && values[w.id].length > 0)
        values.roles.push(...values[w.id]);
    })

    //.......... check name column ..............
    if (values["federated"]) {
      values["name"] = '';
    }
    //............................................
    return values;
  }

  CreateUser(values: any) {
    return AddUser(this.prepareUserData(values));
  }

  EditUser(values: UserDto) {
    return UpdateUser(this.prepareUserData(values));
  }

  ResetUserPassword(values: UserDto) {
    return ResetUserPassword(values.user_id);
  }

  setFilters(filters: Record<string, any>) {
    this.filters = filters;
  }

  FilterData() {

    this.gridData = [...this.users];

    keys(this.filters).forEach(key => {
      if (this.filters[key] === "") {
        return;
      }
      const value = this.filters[key];
      if (key === "name") {
        this.gridData = this.gridData.filter(item => item.name.toLowerCase().search(value.toLowerCase()) !== -1
          || item.username.toLowerCase().search(value.toLowerCase()) !== -1);
      }
      else if (key === "enabled") {
        if (value === "__all") {
          return;
        }
        this.gridData = this.gridData.filter(item => value === "true" ? item.enabled : !item.enabled);
      }
    })
  }

  static validateUserName(username: string): string {
    const maxLength = 100;

    if (username.length > maxLength) {
      return `User name cannot exceed ${maxLength} characters.`
    }
    const restrictedPrefixes = ["activedirectory_"];
    for (const word of restrictedPrefixes) {
      if (username.toLowerCase().indexOf(word) === 0) {
        return `User name cannot start with ${word}`
      }
    }

    return "";
  }
}
