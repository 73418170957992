import { Horizontal, StretchSpacer } from 'gls';
import UsersSearchField from './UsersSearchField';
import { style } from 'typestyle';
import { colours } from 'styling/colours';
import DownloadLogsButton from './DownloadLogsButton';

type RecentUsersHeaderProps = {
  SearchHandler: (searchTerm: string, searchIn?: string) => void;
};

export const RecentUsersHeader = ({ SearchHandler: SearchHanlder }: RecentUsersHeaderProps) => {

  return (
    <div className="container">
      <Horizontal className={headerStyle}>
        <UsersSearchField SearchHanlder={SearchHanlder} />
        <StretchSpacer />
        <DownloadLogsButton />
      </Horizontal>
    </div>
  );
};

const headerStyle = style({
  width: '100%',
  padding: "10px",
  marginBottom: "10px",
  backgroundColor: colours.panelBackground,
  borderRadius: "6px",
})