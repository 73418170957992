import axios, { AxiosError } from "axios";
import download from "downloadjs";
import { debounce } from "lodash";
import { ErrorResponseDto } from "api/config/ErrorResponseDto";
import { RootStore } from "stores/RootStore";

const API_TIMEOUT = 60000;
const DEBOUNCE_DELAY = 300;

function axiosConfig() {
  return {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${RootStore.authToken}`,
    },
    timeout: API_TIMEOUT
  }
};

function errorHandler(err: AxiosError): ErrorResponseDto {
  const msg = (err.response?.data as Record<string, string>);
  const error = new ErrorResponseDto(msg.detail, err.response?.status);
  return error;
}

export async function genericGet<TResult>(url: string): Promise<TResult> {

  return axios.get(url, axiosConfig())
    .then((response) => {
      return response.data as unknown as TResult;
    })
    .catch((error) => { throw errorHandler(error) });
}

export function blobDownload<TPostData>(url: string, body?: TPostData): Promise<boolean> {
  let filename = "";
  return fetch(url, {
    method: "POST",
    headers: { ...axiosConfig().headers },
    body: body ? JSON.stringify(body) : null
  })
    .then(res => {
      const disposition = res.headers.get('content-disposition');
      filename = disposition?.split(/;(.+)/)[1].split(/=(.+)/)[1] || `${new Date().toLocaleDateString()}`;
      filename = filename.replaceAll('"', '');
      return res.blob();
    })
    .then((blob) => {
      download(blob, filename)
      return Promise.resolve(true)
    })
    .catch((error) => {
      throw error
    });
}

async function _genericPost<TResult, TPostData>(
  url: string,
  body: TPostData,
): Promise<TResult> {

  return axios.post(url, body || null, axiosConfig())
    .then((response): Promise<TResult> => {
      return Promise.resolve((response.data || true) as unknown as TResult);
    })
    .catch((error) => { throw errorHandler(error) });
}

export function genericDelete<TResult>(
  url: string,
  body: any = "",
): Promise<TResult> {
  return axios.delete(
    url,
    {
      data: body,
      ...axiosConfig()
    }
  )
    .then((response): Promise<TResult> => {
      return Promise.resolve((response.data || true) as unknown as TResult);
    })
    .catch((error) => { throw errorHandler(error) });
}

async function _genericPut<TResult, TPostData>(url: string, body?: TPostData): Promise<TResult> {
  return axios.put(url, body || null, axiosConfig())
    .then((response): Promise<TResult> => {
      return Promise.resolve((response.data || true) as unknown as TResult);
    })
    .catch((error) => { throw errorHandler(error) });
}

//------------------------------------------

const debouncedGenericPost = debounce(
  (url: string, body: any, resolve: any, reject: any) => {
    _genericPost(url, body).then(resolve).catch(reject);
  },
  DEBOUNCE_DELAY,
  {
    leading: true
  }
);

export function genericPost<TResult, TPostData>(
  url: string,
  body: TPostData,
): Promise<TResult> {
  return new Promise((resolve, reject) => {
    debouncedGenericPost(url, body, resolve, reject);
  });
}

const debouncedGenericPut = debounce(
  (url: string, body: any, resolve: any, reject: any) => {
    _genericPut(url, body).then(resolve).catch(reject);
  },
  DEBOUNCE_DELAY,
  {
    leading: true
  }
);

export function genericPut<TResult, TPostData>(
  url: string,
  body?: TPostData
): Promise<TResult> {
  return new Promise((resolve, reject) => {
    debouncedGenericPut(url, body, resolve, reject);
  });
}