import { Cache } from 'aws-amplify/utils';
import { AuthStateTypes } from 'hooks/useAuth.hook';

export class LocalAuth {
  _navigate: any;

  constructor(navigate: any) {
    this._navigate = navigate;
  }

  async signOut() {
    localStorage.removeItem('aws-amplify-cacheauthState');
    window.location.href = 'http://localhost:9000/session/end';
  }

  async currentAuthenticatedUser(): Promise<AuthStateTypes> {
    const session = localStorage.getItem('aws-amplify-cacheauthState');
    if (session == null) {
      throw new Error('No session');
    }

    return JSON.parse(session).data
  }

  signInWithRedirect() {
    let url = new URL('http://localhost:9000/auth');
    url.searchParams.append('response_type', 'code');
    url.searchParams.append('scope', 'openid');
    url.searchParams.append('client_id', 'client_id');
    url.searchParams.append('redirect_uri', 'http://localhost/local-login');

    window.location.href = url.href;
  }

  currentSession() {
    return { accessToken: '' };
  }

  static async tokenRequest(code: string) {
    const response = await fetch(`http://localhost:9000/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
        Authorization: `Basic ${btoa('client_id:client_secret')}`,
      },
      body: new URLSearchParams({
        grant_type: 'authorization_code',
        client_id: 'client_id',
        redirect_uri: 'http://localhost/local-login',
        code: code,
      }),
    });

    const authResponse = await response.json();

    const authState : AuthStateTypes = {
      userId: 'test',
      username: 'test',
      isAuthenticated: true,
      jwtToken: authResponse['id_token'],
      accessToken: authResponse['access_token']
    };
    Cache.setItem('authState', authState);
  }
}
