import { icons } from "assets/icons";
import { Content, Vertical } from "gls/lib";
import { observer } from "mobx-react";
import React, { useEffect, useMemo } from "react";
import RowAction from "./components/RowAction";
import { useSystemAdminPermissionStore, useSystemAdministratorsStore } from "contexts/RootStore.context";
import { routePath } from "router/routePaths";
import LoadingScreen from "components/Loading/LoadingScreen";
import Button from "components/Button/Button";
import { GenericModal } from "components/Modals/GenericModal";
import HeaderDefault from "components/Header/HeaderDefault";
import SystemAdminForm from "./components/SystemAdminForm";
import { alertErrorMessage, alertSuccessMessage } from "utils/alerts";
import { useNavigate } from "react-router-dom";
import useDevice from "hooks/useDevice";
import WidthNotSupportedPage from "views/WidthNotSupportedPage/WidthNotSupportedPage";
import { ErrorResponseDto } from "api/config/ErrorResponseDto";
import { StyledDataTableWrapper } from "components/DataTable/style";
import DataTable, { TableColumn } from "react-data-table-component";
import { SystemAdminUserDto } from "./SystemAdminUserDto";
import DefaultCell from "components/DataTable/cells/DefaultCell";
import { datatableStyle } from "components/DataTable/DataTableStyling.config";

const SystemAdminList = observer(() => {
  const {
    listLoading,
    editingMode,
    currentAdmin,
    setCurrentAdmin,
    setEditingMode,
    deleteModalShow,
    setDeleteModalShow,
    deleteAdmin,
    loadAdmins,
    loading,
    systemAdmins,
    reset,
  } = useSystemAdministratorsStore();

  const navigate = useNavigate();
  const { isMobileDevice } = useDevice();
  const { canManageSystemAdministrators, canViewConfig } = useSystemAdminPermissionStore();

  if (!canViewConfig) {
    navigate(routePath.unauthorised);
  }

  const columns = useMemo(() => {
    return [
      {
        name: 'Name',
        selector: (row: SystemAdminUserDto) => <DefaultCell value={row.name} />,
      },
      {
        right: true,
        sortable: false,
        cell: (row: SystemAdminUserDto) => <RowAction admin={row} />,
        width: '200px',
      }
    ] as TableColumn<SystemAdminUserDto>[];
  }, [])

  useEffect(() => {
    const abortController = new AbortController();
    loadAdmins()
      .finally(() => {
        if (abortController.signal.aborted) {
          reset();
        }
      });

    return () => {
      abortController.abort();
      reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteHandler = () => {
    deleteAdmin()
      .then(() => {
        alertSuccessMessage(`${currentAdmin?.name} has been removed as a system administrator`)
      })
      .catch((error: ErrorResponseDto) => {
        alertErrorMessage(error.errorMsg)
      })
  }

  if (isMobileDevice) {
    return <WidthNotSupportedPage />;
  }

  return listLoading ? (
    <LoadingScreen />
  ) : (
    <>
      <HeaderDefault itemCount={systemAdmins.length} CountTitle="Total Admins" />
      {editingMode && (<SystemAdminForm />)}
      {!editingMode && (
        <Vertical>
          {canManageSystemAdministrators && (
            <Content horizontalAlign="right">
              <Button
                onClick={() => {
                  setCurrentAdmin(null)
                  setEditingMode(true)
                }}
                id="add-system-admin"
                size="flexible"
              >
                Add System Administrator
              </Button>
            </Content>
          )}
          <StyledDataTableWrapper className={`table-container}`}>
            <DataTable
              columns={columns}
              data={systemAdmins}
              defaultSortFieldId={4}
              defaultSortAsc={false}
              customStyles={datatableStyle}
            />
          </StyledDataTableWrapper>
        </Vertical>
      )}

      <GenericModal
        title="Remove"
        subtitle={
          <span>
            Are you sure you want to remove <strong>{currentAdmin?.name}</strong> as a
            system administrator?
          </span>
        }
        actionName="Remove"
        actionStyle="cancel"
        icon={<icons.Remove height={40} width={40} />}
        isOpen={deleteModalShow}
        onClose={() => setDeleteModalShow(false)}
        onAction={deleteHandler}
        loading={loading}
      />
    </>
  );
});

export default SystemAdminList;
