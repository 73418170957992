import {
  genericDelete,
  genericGet,
  genericPost,
  genericPut,
} from "api/config/base";
import { WorkGroup, WorkgroupDto, WorkgroupRequestDto } from "views/config/workgroups/WorkgroupDto";
import { getConfigApiUrl } from "utils/utils";

export function getWorkgroups(): Promise<WorkGroup[]> {
  return genericGet(`${getConfigApiUrl()}/v1/workgroups`);
}

export function postCreateWorkgroups(workGrworkgroups: WorkgroupRequestDto[]): Promise<WorkgroupDto> {
  return genericPost(
    `${getConfigApiUrl()}/v1/workgroups`, { workgroups: workGrworkgroups });
}

export function putChangeWorkgroupName(
  workgroupId: string,
  workgroupName: string
): Promise<WorkgroupDto> {
  const workgroup = { id: workgroupId, name: workgroupName }
  return genericPut(`${getConfigApiUrl()}/v1/workgroups`, { workgroup });
}

export function deleteWorkgroup(
  workgroupId: string
): Promise<boolean> {
  return genericDelete(`${getConfigApiUrl()}/v1/workgroups/${workgroupId}`);
}
