import { icons } from "assets/icons";
import { Horizontal } from "gls/lib";
import React from "react";
import { style } from "typestyle";
import { useSystemAdminPermissionStore, useSystemAdministratorsStore } from "contexts/RootStore.context";
import IconButton from "components/Button/IconButton";
import { colours } from "styling/colours";
import { SystemAdminUserDto } from "../SystemAdminUserDto";

type RowActionProps = {
  admin: SystemAdminUserDto
}
const RowAction = ({ admin }: RowActionProps) => {

  const { setCurrentAdmin, setEditingMode, setDeleteModalShow } = useSystemAdministratorsStore();
  const { canManageSystemAdministrators } = useSystemAdminPermissionStore();
  return (

    <Horizontal
      spacing={18}
      verticalAlign="center"
      horizontalAlign="right"
      className={paddingStyle}
    >
      <>
        <IconButton
          icon={
            canManageSystemAdministrators ? (
              <icons.EditIconOutlined
                width={18}
                height={18}
                fill={colours.secondary}
                id='edit-system-admin'
              />
            ) : (
              <icons.OpenEyeIcon
                width={18}
                height={18}
                fill={colours.secondary}
                id='view-system-admin'
              />
            )
          }
          onClick={() => {
            setCurrentAdmin(admin);
            setEditingMode(true)
          }}
        />
        {canManageSystemAdministrators && (
          <IconButton
            icon={<icons.Remove width={18} height={18} />}
            onClick={() => {
              setCurrentAdmin(admin);
              setDeleteModalShow(true)
            }}
            id='delete-system-admin'
          />
        )}
      </>
    </Horizontal>
  );
};
export default RowAction;

const paddingStyle = style({
  padding: "20px 0",
});
